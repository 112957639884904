import Vue from 'vue';
export default {

    onMounted() {
        this.keyField = this.params.keyField;
        this.displayField = this.params.displayField;
        this.items = this.params.values;
        if (this.items) {
            this.items.forEach(item => {
                this.valuesMap[item[this.keyField]] = item[this.displayField];
            });    
        }
        this.value = this.getValueForKey(this.params.value); // this.valuesMap[val];
        Vue.nextTick(() => {
            const comboBox = this.$refs.combobox;
            if (this.params.eventKey != null && this.params.eventKey != "Enter") {
                this.value = this.params.eventKey; //Take over value of keypress on datagrid to the editor value  (Excel behaviour)            
            }
            comboBox.focus();
        });
    },

    onChange(ea) {
        this.value = ea[this.displayField];
        this.key = ea[this.keyField];
        this.params.stopEditing();
    },

    onKeyDown(ea) {
        const me = this;
        const comboBox = this.$refs.combobox;
        const menuIndex = comboBox.getMenuIndex();

        if (ea.key == 'Escape') {
            this.params.stopEditing(true);
            this.isValid = false;
        } else if (ea.key == 'Enter') {
            const val = me.items[menuIndex];
            if (val) {
                this.value = val[this.displayField];
                this.key = val[this.keyField];
            }
            this.params.stopEditing();
            this.isValid = true;
        } else if (ea.key == 'Tab') {
            ea.stopPropagation();
            const val = me.items[menuIndex];
            if (val) {
                this.value = val[this.displayField];
                this.key = val[this.keyField];
            }
            this.params.stopEditing();
            setTimeout(() => {
                me.params.api.tabToNextCell();
            }, 50);
        }
    },

    getValueForKey(keyValue) {
        return this.valuesMap[keyValue]
    },

    /* Component Editor Lifecycle methods */
    // the final value to send to the grid, on completion of editing
    getValue() {
        return this.key;
    },

    // Gets called once before editing starts, to give editor a chance to
    // cancel the editing before it even starts.
    isCancelBeforeStart() {
        return false;
    },

    // Gets called once when editing is finished (eg if Enter is pressed).
    // If you return true, then the result of the edit will be ignored.
    isCancelAfterEnd() {
        if (this.key == null) return true;
        return false;
    }

}