import Vue from "vue";
import api from "@/api.js";
import moment from "moment";
import { showSnackbar } from "@/common/globalActions";

export default {
  async onNext() {
    const me = this;
    const res = await api.get(`clients?customerName=${me.customerName.replace("&", "%26").replace("+", "%2B")}`);
    if (res.data == "") {
      me.customerError = this.$t("CustomerDoesNotExist");
      me.clients = [];
      this.$refs.customerField.focus();
      return;
    }
    me.customerError = "";
    me.clients = res.data;
    this.step1 = false;
    this.step2 = true;

    var clientName = localStorage.getItem("clientName");
    if (clientName) {
      me.clients.forEach((client) => {
        if (client.ClientName == clientName) {
          me.client = client;
          return;
        }
      });
    }

    localStorage.setItem("loginCustomer", me.customerName);
    Vue.nextTick(() => {
      if (me.selectedClientUID == "") this.$refs.clientField.focus();
      else this.$refs.userField.focus();
    });
  },
  onBack() {
    this.step1 = true;
    this.step2 = false;
    Vue.nextTick(() => {
      this.$refs.customerField.focus();
    });
  },
  onCustomerChange() {
    this.customerError = "";
  },
  async onLogin() {
    const me = this;

    me.showClientNotSelectedError = !me.client;
    if (!me.client) {
      return;
    }
    localStorage.setItem("clientName", me.client.ClientName);
    Vue.prototype.$lastSyncTime = moment(me.client.LastSyncTime);
    const params = new URLSearchParams();
    params.append("grant_type", "password");
    params.append("Username", this.getLogin());
    params.append("Password", me.password);
    const options = {
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: params,
      url: "login",
    };
    let response;
    try {
      response = await api(options);
    } catch (err) {
      me.loginError = me.$t("WrongUserOrPassword");
      throw err;
    }
    me.$emit("loginSucceeded", response.data);
  },
  onCustomerKeyPress(e) {
    if (e.key == "Enter") this.onNext();
  },
  onChangeMode(e) {
    this.changeMode(e);
  },

  async onForgotPasswordClick() {
    const me = this;

    if (me.client == null) {
      me.$alert({
        text: me.$t("PasswordForgotten_NoUser"),
      });
      return;
    }

    const res = await api.get(
      `user/hasMail?UserName=${me.userName}&ClientName=${me.client.ClientName}&CustomerName=${me.customerName}`
    );
    const hasEmailAddress = res.data;
    if (!hasEmailAddress) {
      me.$alert({
        text: me.$t("PasswordForgotten_NoEmail"),
      });
      return;
    }
    me.$confirm(me.$t("PasswordForgotten_Text"), {
      title: this.$t("PasswordForgotten_Title"),
      buttonTrueText: this.$t("Yes"),
      buttonFalseText: this.$t("No"),
      icon: "mdi-comment-question-outline",
      color: "gray",
    }).then((res) => {
      if (res == true) {
        me.requestPasswortChange();
      }
    });
  },

  getLogin() {
    return `${this.userName}@${this.client.ClientName}.${this.customerName}`;
  },

  requestPasswortChange() {
    const res = api.post("password/reset/selfService", {
      Login: this.getLogin(),
      ChangePasswordUrl: `${process.env.VUE_APP_CLIENT_URL}/setNewPassword`,
    });
    console.log(res);
    showSnackbar(this.$t("PasswordForgotten_EmailSent"), "info");
  },

  changeMode(darkMode) {
    this.$vuetify.theme.dark = darkMode;
    localStorage.setItem("loginDarkMode", darkMode);
  },

  switchLanguage(languageCode) {
    this.languageCode = languageCode;
    this.$i18n.locale = languageCode;
    localStorage.setItem("loginLanguage", this.languageCode);
    if (this.customerError)
      this.customerError = this.$t("CustomerDoesNotExist"); //to re-translate
  },

  onUserKeyDown(e) {
    if (e.key == "Enter") {
      this.onLogin();
    }
  },

  onPasswordKeyDown(e) {
    if (e.key == "Enter") {
      this.onLogin();
    }
  },
};
