<template>
  <v-container fluid>
    <v-btn icon @click="onArrowLeft()">
      <v-icon>mdi-arrow-left-circle</v-icon>
    </v-btn>
    <span class="ml-2">{{ $t("ManageEmployee") }}:</span>
    <span class="ml-2">
      <b>{{ user.UserName }}</b>
    </span>

    <v-tabs class="mt-4" dense v-model="tabIndex">
      <v-tab>{{ $t("General") }}</v-tab>
      <v-tab v-if="userUID != 'new'">{{ $t("Settings") }}</v-tab>
      <v-tab v-if="userUID != 'new'">{{ $t("WorkTypes") }}</v-tab>
      <v-tab v-if="userUID != 'new'">{{ $t("Roles") }}</v-tab>
      <v-tab v-if="userUID != 'new'">{{ $t("Team") }}</v-tab>
      <v-tab v-if="userUID != 'new'">{{ $t("Devices") }}</v-tab>
    </v-tabs>

    <employee-general-data
      v-if="tabIndex == 0"
      ref="employeeGeneralData"
      :userUID="userUID"
      @SubComponentChanged="onSubComponentChanged($event)"
      @IsDirtyChanged="onIsDirtyChanged($event)"
      class="mt-4"
    ></employee-general-data>
    <employee-settings
      ref="employeeSettings"
      v-if="tabIndex == 1"
      :userUID="userUID"
      @SubComponentChanged="onSubComponentChanged($event)"
      @IsDirtyChanged="onIsDirtyChanged($event)"
      class="mt-4"
    ></employee-settings>
    <employee-workTypes
      ref="employeeWorkTypes"
      v-if="tabIndex == 2"
      :userUID="userUID"
      @SubComponentChanged="onSubComponentChanged($event)"
      @IsDirtyChanged="onIsDirtyChanged($event)"
      class="mt-4"
    ></employee-workTypes>
    <employee-roles
      ref="employeeRoles"
      v-if="tabIndex == 3"
      :userUID="userUID"
      @SubComponentChanged="onSubComponentChanged($event)"
      @IsDirtyChanged="onIsDirtyChanged($event)"
      class="mt-4"
    ></employee-roles>
    <employee-team
      ref="employeeTeam"
      v-if="tabIndex == 4"
      :userUID="userUID"
      @SubComponentChanged="onSubComponentChanged($event)"
      @IsDirtyChanged="onIsDirtyChanged($event)"
      class="mt-4"
    ></employee-team>
    <employee-devices
      ref="employeeDevices"
      v-if="tabIndex == 5"
      :userUID="userUID"
      @SubComponentChanged="onSubComponentChanged($event)"
      @IsDirtyChanged="onIsDirtyChanged($event)"
      class="mt-4"
    ></employee-devices>

    <v-dialog
      overlay-color="blue darken-3"
      v-model="changePasswordForEmployDialog"
      scrollable
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span>{{ $t("ChangePasswordForEmployee") }}</span>
          <v-spacer></v-spacer>
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                @click="changePasswordForEmployDialog = false"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
          </v-menu>
        </v-card-title>
        <v-card-text style="height: 200px">
          <v-form
            ref="form"
            v-model="isNewPasswordValid"
            @keyup.native.enter="setNewPassword"
          >
            <set-password-field
              ref="setPasswordField"
              :password="newPassword"
              @updatePassword="onUpdatePassword($event)"
            ></set-password-field>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="standard" @click="onCancelSetNewPassword()">
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn color="primary" @click="onOKSetNewPassword()">
            {{ $t("SetNewPassword") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-alert
      type="success"
      icon="mdi-information"
      elevation="2"
      dismissible
      class="mt-4"
      transition="scale-transition"
      v-if="displayAlert"
    >
      {{ alertText }}
    </v-alert>
  </v-container>
</template>
<style lang="scss"></style>
<script>
import api from "@/api.js";
import EmployeeGeneralData from "./EmployeeGeneralData.vue";
import EmployeeSettings from "./EmployeeSettings.vue";
import EmployeeWorkTypes from "./EmployeeWorkTypes.vue";
import EmployeeRoles from "./EmployeeRoles.vue";
import EmployeeTeam from "./EmployeeTeam.vue";
import EmployeeDevices from "./EmployeeDevices.vue";
import SetPasswordField from "../../other/SetPasswordField.vue";
import { showSnackbar } from "@/common/globalActions";

export default {
  name: "employee-form",

  async created() {
    const me = this;
    if (me.userUID == "new") {
      me.user = {};
    } else {
      const res = await api.get(`user?userUID=${me.userUID}`);
      me.user = res.data;
    }
  },

  async mounted() {},

  props: {
    userUID: String,
  },

  data: () => ({
    showDividers: true,
    user: {},
    tabIndex: 0,
    workTypes: [],
    newPassword: "",
    changePasswordForEmployDialog: false,
    isNewPasswordValid: false,
    displayAlert: false,
    alertText: "",
  }),

  methods: {
    onUpdatePassword(newPassword) {
      this.newPassword = newPassword;
    },

    getCurrentTabComponent() {
      const me = this;
      if (me.tabIndex == 0) return me.$refs.employeeGeneralData;
      if (me.tabIndex == 1) return me.$refs.employeeSettings;
      if (me.tabIndex == 2) return me.$refs.employeeWorkTypes;
      if (me.tabIndex == 3) return me.$refs.employeeRoles;
      if (me.tabIndex == 4) return me.$refs.employeeTeam;
      if (me.tabIndex == 5) return me.$refs.employeeDevices;
      return null;
    },

    setGridLanguage() {
      const me = this;
      const c = me.getCurrentTabComponent();
      if (c && c.setGridLanguage) c.setGridLanguage();
    },

    addNewRecord() {
      const me = this;
      const c = me.getCurrentTabComponent();
      c.addNewRecord();
    },

    addNewRecord_isInvisible() {
      const me = this;
      const c = me.getCurrentTabComponent();
      if (c == null) return true;
      return c.addNewRecord_isInvisible();
    },

    refresh() {
      const me = this;
      const c = me.getCurrentTabComponent();
      c.refresh();
    },

    undo() {
      const me = this;
      const c = me.getCurrentTabComponent();
      c.undo();
    },

    save() {
      const me = this;
      const c = me.getCurrentTabComponent();
      c.save();
    },

    async resetPassword() {
      const me = this;
      //reload user to sure that Email is there
      const res = await api.get(`user?userUID=${me.userUID}`);
      me.user = res.data;
      if (me.user.EmailAddress && me.user.EmailAddress.length > 0) {
        me.initiateRequestPasswortChangeForUser();
        return;
      }

      me.newPassword = "";
      me.changePasswordForEmployDialog = true;
      setTimeout(() => {
        me.$refs.setPasswordField.focus();
      }, 300);
    },

    initiateRequestPasswortChangeForUser() {
      const me = this;
      me.$confirm(me.$t("PasswordResetForUser_Text"), {
        title: this.$t("PasswordResetForUser_Title"),
        buttonTrueText: this.$t("Yes"),
        buttonFalseText: this.$t("No"),
        icon: "mdi-comment-question-outline",
        color: "gray",
      }).then((res) => {
        if (res == true) {
          me.requestPasswortChangeForUser();
        }
      });
    },

    requestPasswortChangeForUser() {
      api.post("password/reset/Admin", {
        UserUID: this.user.UserUID,
        ChangePasswordUrl: `${process.env.VUE_APP_CLIENT_URL}/setNewPassword`,
      });
      showSnackbar(this.$t("PasswordResetForUser_EmailSent"), "info");
    },

    onCancelSetNewPassword() {
      this.changePasswordForEmployDialog = false;
    },

    onOKSetNewPassword() {
      this.setNewPassword();
    },

    copyWorkTypesFromOtherUser_isInvisible() {
      const me = this;
      const c = me.getCurrentTabComponent();
      if (c == null) return true;
      return !c.copyWorkTypesFromOtherUser;
    },

    async copyWorkTypesFromOtherUser() {
      const me = this;
      const c = me.getCurrentTabComponent();
      if (c == null) return;
      if (c.copyWorkTypesFromOtherUser) c.copyWorkTypesFromOtherUser();
    },

    async setNewPassword() {
      const me = this;
      this.$refs.form.validate();
      if (!me.isNewPasswordValid) return;

      try {
        await api.post("password/admin", {
          UserUID: this.userUID,
          Password: this.newPassword,
        });
        me.changePasswordForEmployDialog = false;
        me.alertText = this.$t("The password of the user has been changed.");
        me.displayAlert = true;
        setTimeout(() => {
          me.displayAlert = false;
        }, 3000);
      } catch (err) {
        console.log(err);
        alert("An unexpected error has occured");
      }
    },

    onIsDirtyChanged(e) {
      this.$emit("IsDirtyChanged", e);
    },

    onSubComponentChanged(e) {
      this.$emit("SubComponentChanged", e);
    },

    onArrowLeft() {
      //TODO: Dirty check, Save-Changes Warning
      this.$router.push("/app/manageEmployees");
    },
  },

  computed: {
    getStyle() {
      //magic number 200 is the height of the toolbar + footer
      //TODO: Calculate size dynamically instead of using a fixed magic number
      let subtractHeight = this.displayAlert ? 265 : 180;
      return (
        "width:100%;margin-top:10px;height:" +
        (parseInt(this.$vssHeight) - subtractHeight) +
        "px"
      );
    },
  },
  components: {
    EmployeeGeneralData,
    EmployeeSettings,
    EmployeeWorkTypes,
    EmployeeRoles,
    EmployeeTeam,
    EmployeeDevices,
    SetPasswordField,
  },
};
</script>
