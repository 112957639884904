import api from "@/api.js";
export default {
  onMounted() {
    const me = this;
    me.caption = me.params.caption;
    me.apiUrl = me.params.apiUrl;
    me.columnDefs = me.params.columnDefs;
    me.preFilterDelegate = me.params.preFilterDelegate;
    me.displayFormatDelegate = me.params.displayFormatDelegate;
    me.writeBackDelegate = me.params.writeBackDelegate;
    me.gridData = me.params.data;
    me.keyField = me.params.keyField; //WorkTypeUID
    me.displayField = me.params.displayField; //WorkTypeAndId
    me.searchExactField = me.params.searchExactField;
    me.searchText =
      me.params.eventKey == null || me.params.eventKey == "Enter"
        ? this.params.data[me.displayField]
        : this.params.eventKey;
    me.dialogSearchText =
      me.params.data[me.keyField] == null
        ? me.params.data[me.displayField]
        : "";
    setTimeout(() => {
      if (me.$refs.textField) me.$refs.textField.focus();
    }, 50);
  },

  onCellFocused(e) {
    const nodes = e.api.getRenderedNodes();
    if (nodes) nodes[e.rowIndex].setSelected(true);
  },

  onCellDoubleClicked(e) {
    this.setSelected(e.data);
  },

  onCellKeyDown(e) {
    if (e.event.key == "Enter") {
      this.setSelected(e.data);
    }
  },
  onKeyDown(e) {
    const me = this;

    if (e.key == "F2") {
      me.openSearchDialog();
    } else if (e.key == "Tab" || e.key == "Enter") {
      if (me.searchText != this.params.data[this.displayField]) {
        e.cancelBubble = true;
        me.searchInField(me.searchText);
      }
      return;
    }
  },

  async searchInField(searchText) {
    const me = this;
    await me.loadData(searchText);
    if (me.items.length == 1) {
      this.setSelected(me.items[0]);
    } else if (me.items.length > 1) {
      if (me.findExactMatch() == false) {
        me.dialogSearchText = me.searchText;
        me.dialog = true;
      }
    }
  },

  findExactMatch() {
    const me = this;
    let found = false;
    let fieldName;
    if (me.searchExactField) fieldName = me.searchExactField;
    else fieldName = me.displayField;
    let i = 0;
    me.items.forEach((element) => {
      if (element[fieldName] == me.searchText) {
        me.setSelected(me.items[i]);
        found = true;
        return found;
      }
      i++;
    });
    return found;
  },

  async loadData(searchText) {
    const me = this;
    const res = await api.get(me.apiUrl); //WorkTypes
    if (me.apiUrl == 'WorkTypes')
    {
      res.data.forEach((row) => {
        const workCategory = 'WorkCategory_' + row.WorkCategory.replace(" ", "_");
        row.WorkCategory = this.$t(workCategory);
      });       
    }
    const prefilteredData = res.data.filter((x) => me.preFilterData(x));
    if (searchText && searchText.trim().length > 0) {
      const data = prefilteredData.filter((x) =>
        me.searchInAllColumns(x, searchText)
      );
      me.items = data;
      return;
    }
    me.items = prefilteredData;
  },

  preFilterData(data) {
    if (!this.preFilterDelegate) return true;
    const val = this.preFilterDelegate(data, this.gridData);
    return val;
  },

  searchInAllColumns(x, searchText) {
    let result = false;
    this.columnDefs.forEach((columnDef) => {
      let val = x[columnDef.field].toString();
      const r = val.toLowerCase().includes(searchText.toLowerCase());
      result |= r;
      if (r == true) return true;
    });
    return result;
  },

  onGridReady() {},

  onRowDataChanged() {
    const me = this;
    me.$refs.dataGrid.api.forEachNode(function(node) {
      if (node.data.WorkTypeUID == me.params.data[me.keyField]) {
        node.setSelected(true);
        me.$refs.dataGrid.api.setFocusedCell(
          node.rowIndex,
          "WorkTypeID"
        );
      }
    });
  },

  async openSearchDialog() {
    const me = this;
    this.loadData(this.dialogSearchText);
    me.dialog = true;
  },

  onSearchDialogKeyDown() {
    this.loadData(this.dialogSearchText);
  },

  rowClicked(item) {
    this.toggleSelection(item);
  },
  toggleSelection(item) {
    if (this.selectedDialogItem == item) {
      this.selectedDialogItem = null;
    } else {
      this.selectedDialogItem = item;
    }
  },

  onKeyPress() {},

  onOK() {
    var focusedCell = this.$refs.dataGrid.api.getFocusedCell();
    var row = this.$refs.dataGrid.api.getDisplayedRowAtIndex(focusedCell.rowIndex);
    if (row == null) {
      this.isValid = false;
      return;
    }
    this.setSelected(row.data);
    this.dialog = false;
  },

  onClear() {
    this.dialogSearchText = "";
    this.searchInDialog(this.dialogSearchText);
  },

  setSelected(item) {
    this.selectedItem = item;
    this.selectedDialogItem = item;
    this.searchText = `${this.selectedItem.WorkTypeId} - ${this.selectedItem.ProjectName}`;
    this.workType = this.selectedItem.WorkType;
    this.cancelEdit = false;
    this.isValid = true;
    let displayValue = this.displayFormatDelegate
      ? this.displayFormatDelegate(this.selectedItem)
      : this.selectedItem[this.displayField];

    let val = {};
    val[this.keyField] = this.selectedItem[this.keyField];
    val[this.displayField] = displayValue;
    if (this.writeBackDelegate) {
      this.writeBackDelegate(val, this.selectedItem);
    }
    this.value = JSON.stringify(val);
    this.params.stopEditing();
    this.params.data[this.displayField] = displayValue;
    this.params.data[this.keyField] = this.value;
  },

  onCancel() {
    this.dialog = false;
  },

  /* Component Editor Lifecycle methods */
  // the final value to send to the grid, on completion of editing
  getValue() {
    return this.value;
  },

  // Gets called once before editing starts, to give editor a chance to
  // cancel the editing before it even starts.
  isCancelBeforeStart() {
    return false;
  },

  // Gets called once when editing is finished (eg if Enter is pressed).
  // If you return true, then the result of the edit will be ignored.
  isCancelAfterEnd() {
    if (this.value == null) return true;
    return false;
    // our editor will reject any value greater than 1000
  },
};
