import api from "@/api.js";
import utils from "@/common/utils.js";
import { showSnackbar } from "@/common/globalActions";

export default {
  addEmptyRows(rows) {
    const me = this;
    const targetRowCount = 14;

    for (let i = rows.length; i < targetRowCount; i++) {
      me.data.Rows.splice(i, 0, me.createNewRow());
    }
  },

  async loadData() {
    const me = this;
    if (me.project == null) {
      showSnackbar(this.$t("PleaseSelectAProject"), "info");
      me.$refs.projectLookupBox.setFocus();
      return;
    }

    me.loadingTemplate = this.$t("ProcessingData");

    me.$nextTick(() => {
      me.$refs.dataGrid.api.showLoadingOverlay();
    });

    let res;
    let url =
      me.showOnlyOneDay == true
        ? `workProject/day?dayDate=${me.$dates.currentDate.format(
            "YYYY-MM-DD"
          )}`
        : `workProject?mondayDate=${me.$dates.mondayDate.format("YYYY-MM-DD")}`;

    if (me.selectedUserUID != null) url += `&userUID=${me.selectedUserUID}`;

    if (me.project != null) url += `&projectUID=${me.project.ProjectUID}`;
    if (me.structure != null)
      url += `&structureUID=${me.structure.StructureUID}`;
    res = await api.get(url);

    const locale = this.$gus.data.languageCode;
    if (me.showOnlyOneDay === true) {
      me.periodText = me.$dates.currentDate
        .locale(locale)
        .format("dd DD. MMM YYYY");
    } else {
      const weekStartDateFormatted = me.$dates.mondayDate
        .locale(locale)
        .format("dd DD. MMM YYYY");
      const weekEndDateFormatted = me.$dates.mondayDate
        .clone()
        .add(6, "days")
        .locale(locale)
        .format("dd DD. MMM YYYY");
      me.periodText = `${weekStartDateFormatted} .. ${weekEndDateFormatted}`;
    }
    me.statusTextCode =
      this.showOnlyOneDay == true && res.data.DayStatus != null
        ? `Status${res.data.DayStatus}`
        : `Status${res.data.WeekState.Status}`;
    me.updateHeaderTexts();

    // me.statusText = this.$t(`Status${res.data.WeekState.Status}`);
    if (me.$refs.dataGrid) {
      me.$refs.dataGrid.api.hideOverlay();
    }
    me.data = res.data;

    if (me.data == null) {
      me.data = {
        MondayDate: me.$dates.mondayDate.format("YYYY-MM-DD"),
        UserUID: me.selectedUserUID,
        Rows: [],
        WeekState: {},
        WorkHourSuffix: '',
        ExpenseSuffix: '',
      };
    }
    if (me.data.Rows == null) {
      me.data.Rows = [];
    }

    me.totalsRowData.forEach(totalRow => {
      totalRow.WorkHourSuffix = me.data.WorkHourSuffix;
      totalRow.ExpenseSuffix = me.data.ExpenseSuffix;
    });    

    me.addEmptyRows(this.data.Rows);

    let rowID = 1;
    me.data.Rows.forEach((row) => {
      me.addAdditionalColumnsForLookups(row);

      row.RowID = rowID++;
      row.MondayValue = this.getWeekdayValue(row, "Monday");
      row.TuesdayValue = this.getWeekdayValue(row, "Tuesday");
      row.WednesdayValue = this.getWeekdayValue(row, "Wednesday");
      row.ThursdayValue = this.getWeekdayValue(row, "Thursday");
      row.FridayValue = this.getWeekdayValue(row, "Friday");
      row.SaturdayValue = this.getWeekdayValue(row, "Saturday");
      row.SundayValue = this.getWeekdayValue(row, "Sunday");

      row.MondayValue_Old = this.getWeekdayOldValue(row, "Monday");
      row.TuesdayValue_Old = this.getWeekdayOldValue(row, "Tuesday");
      row.WednesdayValue_Old = this.getWeekdayOldValue(row, "Wednesday");
      row.ThursdayValue_Old = this.getWeekdayOldValue(row, "Thursday");
      row.FridayValue_Old = this.getWeekdayOldValue(row, "Friday");
      row.SaturdayValue_Old = this.getWeekdayOldValue(row, "Saturday");
      row.SundayValue_Old = this.getWeekdayOldValue(row, "Sunday");

      row.OldKeyValues = {};
      row.OldKeyValues.WorkTypeUID = row.WorkTypeUID;
      row.OldKeyValues.ProjectUID = row.ProjectUID;
      row.OldKeyValues.PhaseUID = row.PhaseUID;
      row.OldKeyValues.StructureUID = row.StructureUID;
      row.OldKeyValues.CostCenterID = row.CostCenterID;
      row.OldKeyValues.BookingText = row.BookingText;
      row.OldKeyValues.WorkAttributes = row.WorkAttributes;

      row.WorkHourSuffix = me.data.WorkHourSuffix;
      row.ExpenseSuffix = me.data.ExpenseSuffix;      
    });
  },

  addAdditionalColumnsForLookups(row) {
    if (row.WorkTypeUID) {
      row["WorkTypeUIDAndNameAndKey"] = JSON.stringify({
        WorkTypeUID: row.WorkTypeUID,
        WorkTypeID: row.WorkTypeID,
        WorkType: row.WorkType,
        WorkTypeAndId: `${row.WorkTypeID} - ${row.WorkType}`,
      });
      row["WorkTypeAndId"] = `${row.WorkTypeID} - ${row.WorkType}`;
    }
    if (row.CostCenterID) {
      row["CostCenterJson"] = JSON.stringify({
        CostCenterID: row.CostCenterID,
        CostCenterDescription: row.CostCenterDescription,
        CostCenterDisplayField: `${row.CostCenterID} - ${row.CostCenterDescription}`,
      });
      row[
        "CostCenterDisplayField"
      ] = `${row.CostCenterID} - ${row.CostCenterDescription}`;
    }
  },

  async loadHeaderData() {
    const me = this;
    const res = await me.getUserSetting(
      me.$user.UserUID.toLowerCase(),
      me.projectUserSettingsKey
    );
    const projectUID = res.data.SettingValue;
    if (projectUID) {
      const res = await api.get(`Project?projectUID=${projectUID}`);
      me.project = res.data;
      me.projectSearchText = me.project.ProjectName;
    }
    const res2 = await me.getUserSetting(
      me.$user.UserUID.toLowerCase(),
      me.structureUserSettingsKey
    );
    const structureUID = res2.data.SettingValue;
    if (structureUID) {
      const res = await api.get(`Project?projectUID=${projectUID}`);
      me.project = res.data;
      me.projectSearchText = me.project.ProjectName;
    }
  },

  getWeekdayValue(row, weekday) {
    if (!row) return null;
    if (!row.Values) return null;
    const x = row.Values.find((o) => o.DayOfWeek === weekday);
    if (!x) return null;
    return x.Value;
  },

  getWeekdayOldValue(row, weekday) {
    if (!row) return null;
    if (!row.Values) return null;
    const x = row.Values.find((o) => o.DayOfWeek === weekday);
    if (!x) return null;
    return x.oldValue;
  },

  async loadGlobalWorkTypes() {
    const me = this;
    const response = await api.get(`clientSetting?SettingName=WorkTypes`);
    me.workTypesClientSetting = response.data;
    let globalWorkTypesCsv = this.workTypesClientSetting.SettingValue;
    if (globalWorkTypesCsv) {
      globalWorkTypesCsv = globalWorkTypesCsv.toLowerCase();
      me.workTypeUIDs = globalWorkTypesCsv.split(",");
    }
  },

  async loadReferenceData() {
    const me = this;
    await me.loadGlobalWorkTypes();
    me.totalsRowData = [
      {
        ProjectUID: null,
        WorkTypeUID: "",
        WorkCategoryID: null,
        WorkCategory: "",
        PhaseUID: null,
        StructureUID: null,
        CostCenterID: null,
        BookingText: "",
        MondayValue: 0,
        TuesdayValue: 0,
        WednesdayValue: 0,
        ThursdayValue: 0,
        FridayValue: 0,
        SaturdayValue: 0,
        SundayValue: 0,
        TotalsValue: 0,
        WorkHourSuffix: "",
        ExpenseSuffix: "",
      },
      {
        ProjectUID: null,
        WorkTypeUID: "",
        WorkCategoryID: null,
        WorkCategory: "",
        PhaseUID: null,
        StructureUID: null,
        CostCenterID: null,
        BookingText: "",
        MondayValue: 0,
        TuesdayValue: 0,
        WednesdayValue: 0,
        ThursdayValue: 0,
        FridayValue: 0,
        SaturdayValue: 0,
        SundayValue: 0,
        TotalsValue: 0,
        WorkHourSuffix: "",
        ExpenseSuffix: "",        
      },
    ];

    let res = null;
    if (me.projectUID) {
      res = await api.get(`Project?projectUID=${me.projectUID}`);
      // me.project = res.data;
    }

    // res = await api.get(`workTypes/bysettingsandweek?userUID=${me.selectedUserUID}&mondayDate=${me.currentDate.format('YYYY-MM-DD')}`);
    // me.workTypes = res.data;
    // me.workTypes.forEach(workType => {
    //     me.workTypeLookup[workType.WorkTypeUID] = workType;
    // });

    res = await api.get("workTypes");
    me.workTypes = res.data;
    me.workTypes.forEach((workType) => {
      me.workTypeLookup[workType.WorkTypeUID] = workType;
    });

    res = await api.get("workCategories");
    me.workCategories = res.data;
    me.workCategories.forEach((workCategory) => {
      me.workCategoryLookup[workCategory.WorkCategoryID] = workCategory;
    });

    if (utils.hasUserRole(me.$user, "Controller")) {
      me.users = this.$allUsers;
    } else if (utils.hasUserRole(me.$user, "Teamleader")) {
      me.users = this.$teamUsers;
    }
    me.users.push({ UserUID: me.$user.UserUID, UserName: me.$user.UserName });

    me.users.forEach((user) => {
      me.userLookup[user.UserUID] = user;
    });

    me.teamUsers = me.users;

    res = await api.get("costCenters");
    me.costCenters = res.data;
    me.costCenters.forEach((costCenter) => {
      me.costCenterLookup[costCenter.CostCenterID] = costCenter;
    });
  },
};
