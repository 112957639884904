import moment from "moment";
import api from "@/api.js";

export default {
  async onProjectChanged(e) {
    const me = this;
    me.project = e;
    me.projectSearchText = e.ProjectName;
    const res = await api.get(`structures?projectUID=${e.ProjectUID}`);
    me.structuresFlat = res.data;
    if (me.$refs.structureLookupBox) me.$refs.structureLookupBox.reset();
    me.refresh();
    me.setUserSetting(
      me.selectedUserUID,
      me.projectUserSettingsKey,
      e.ProjectUID
    );
  },

  onStructureChanged(e) {
    const me = this;
    me.structure = e;
    me.refresh();
    me.setUserSetting(
      me.selectedUserUID,
      me.structureUserSettingsKey,
      me.structure == null ? "" : me.structure.StructureUID
    );
  },

  isWeekdayField(fieldName) {
    return (
      fieldName == "MondayValue" ||
      fieldName == "TuesdayValue" ||
      fieldName == "WednesdayValue" ||
      fieldName == "ThursdayValue" ||
      fieldName == "FridayValue" ||
      fieldName == "SaturdayValue" ||
      fieldName == "SundayValue"
    );
  },

  getWeekday(fieldName) {
    if (!this.isWeekdayField(fieldName)) return null;
    return fieldName.replace("Value", "");
  },

  getExistingWorkValue(data, fieldName) {
    let value = data[fieldName];
    if (!value || !data.Values) return null;
    let weekDay = this.getWeekday(fieldName);
    const x = data.Values.filter((x) => x.DayOfWeek == weekDay);
    if (x == null || x.length == 0) return null;
    let weekDayValue = x[0];
    weekDayValue.Value = parseFloat(value);
    return weekDayValue;
  },

  async onKeydown(ea) {
    const me = this;
    if (ea.ctrlKey == true && ea.code == "KeyS") {
      ea.stopPropagation();
      ea.preventDefault();
      ea.cancelBubble = true;
      await me.save();
      return false;
    }
    if (ea.ctrlKey == true && ea.key == "z") {
      ea.stopPropagation();
      ea.preventDefault();
      ea.cancelBubble = true;
      me.undo();
      return false;
    }
    // // if (ea.key == "Delete") {
    // //     const dataGrid = this.$refs.dataGrid;
    // //     if (dataGrid) {
    // //         ea.stopPropagation();
    // //         ea.preventDefault();
    // //         ea.cancelBubble = true;

    // //         var focusedCell = dataGrid.api.getFocusedCell();
    // //         if (focusedCell.column.colDef.isHeaderColumn) return;
    // //         const row = this.data.Rows[focusedCell.rowIndex];
    // //         const editable = focusedCell.column.colDef.editable({
    // //             node: focusedCell
    // //         });
    // //         if (editable === false) return;
    // //         row[focusedCell.column.colDef.field] = null;
    // //         console.log(row[focusedCell.column.colDef.field]);
    // //         dataGrid.api.setGridOption('rowData', this.data.Rows);
    // //         dataGrid.api.stopEditing();
    // //         row.HasChanges = true;
    // //         this.$emit('IsDirtyChanged', row);
    // //         me.updateTotals();
    // //         dataGrid.api.setFocusedCell(focusedCell.rowIndex, focusedCell.column.colId);
    // //         return true;
    // //     }
    // }
  },

  onGridCellValueChanged(event) {
    const me = this;
    event.data.HasChanges = true;
    event.api.redrawRows();
    const fieldName = event.column.colDef.field;

    //Special treatment for Structure-TreeView workaround (combined field was bound)
    // if (fieldName == 'ProjectUIDAndName') {
    //     const val = event.newValue;
    //     const o = JSON.parse(val);
    //     event.data.ProjectUID = tokens[0];
    //     event.data.ProjectName = tokens[1];

    //     if (val != null && val.indexOf(',') > 0) {
    //         const tokens = val.split(',');
    //         event.data.ProjectUID = tokens[0];
    //         event.data.ProjectName = tokens[1];
    //     }
    // } else
    if (fieldName == "ProjectUIDAndKey") {
      const val = event.newValue;
      if (val != null && val.indexOf(",") > 0) {
        const tokens = val.split(",");
        event.data.ProjectUID = tokens[0];
        event.data.ProjectKey = tokens[1];
      }
    } else if (fieldName == "StructureUIDAndName") {
      const val = event.newValue;
      if (val != null && val.indexOf(",") > 0) {
        const tokens = val.split(",");
        event.data.StructureUID = tokens[0];
        event.data.StructureName = tokens[1];
      }
    } else if (fieldName == "CostCenterIDAndDescription") {
      const val = event.newValue;
      if (val != null) {
        const obj = JSON.parse(val);
        event.data.CostCenterID = obj.CostCenterID;
        event.data.CostCenterDescription = obj.CostCenterDescription;
      }
    }
    const dayOfWeek = me.getWeekday(fieldName);
    let weekDayValue = null;
    if (dayOfWeek) {
      weekDayValue = me.getExistingWorkValue(event.data, fieldName);

      if (weekDayValue == null) {
        weekDayValue = {
          //new entry
          Value: parseFloat(event.data[fieldName]),
          DayOfWeek: dayOfWeek,
          WorkUID: null,
          oldValue: null,
        };
      } else {
        if (this.data.WeekState.Status == "Finalized") {
          weekDayValue.oldValue = event.oldValue;
          event.data[fieldName + "_Old"] = event.oldValue;
        }
      }
    }
    this.undoStack.push({
      data: event.data,
      fieldName: fieldName,
      weekDayValue: weekDayValue,
      oldValue: event.oldValue,
    });
    if (!event.data.changeCount) event.data.changeCount = 1;
    else event.data.changeCount++;
    const hasChangesFieldName = event.column.colDef.field + "_HasChanges";
    event.data[hasChangesFieldName] = true;
    this.updateTotals(event);
    me.$emit("IsDirtyChanged", me.isDirty());
  },

  onCellFocused(event) {
    // var focusedCell = event.api.getFocusedCell();
    // console.log(focusedCell);
    // var row = event.api.getDisplayedRowAtIndex(focusedCell.rowIndex);
    // console.log(row.id);
    // const rowNode = event.api.getRowNode(row.id);
    // console.log(rowNode);
    // //console.log(focusedCell.column.colId);
    // var cellValue = event.api.getValue(focusedCell.column.colId, rowNode)
    // console.log(cellValue);

    const nodes = event.api.getRenderedNodes();
    if (nodes && event && event.rowIndex && nodes[event.rowIndex])
      nodes[event.rowIndex].setSelected(true);
  },

  async onColumnSelectionChanged(columnSettings) {
    this.columnSettings = columnSettings;
    this.updateColumnsBySettings();
  },

  async onColumnSelectionOK(columnSettings) {
    this.columnSettings = columnSettings;
    this.updateColumnsBySettings();
    await this.saveUserSettings();
  },

  onWeekdaySelectionChanged(dateValue) {
    this.$dates.currentDate = moment(dateValue);
    this.$dates.mondayDate = this.$dates.currentDate.clone().startOf("isoWeek");
    this.refresh();    
  },

  onReleaseForControlClick() {
    this.confirmChangesBeforeNext(this.isDirty(), this.releaseForControl);
  },

  onReturnToProcessingClick() {
    this.confirmChangesBeforeNext(this.isDirty(), this.returnForProcessing);
  },

  onReleaseForBookingClick() {
    this.confirmChangesBeforeNext(this.isDirty(), this.releaseForBooking);
  },

  onSelectedUserChanged() {
    this.refresh();
  },
};
