<template>
  <div class="main-component">
    <router-view></router-view>
    <div name="snackbar" class="ma-4">
      <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :timeout="snackbar.timeout" max-width="800">
        <div :style="{ color: text_color }" class="text-body-2">{{ snackbar.text }}</div>    

        <template v-slot:action="{ attrs }">
          <v-btn icon v-bind="attrs" @click="snackbar.visible = false">
            <div :class="`v-icon notranslate mdi mdi-close vdp-fake-close-icon`" :style="{ color: text_color }"></div>
          </v-btn>
        </template>      
      </v-snackbar>
    </div>   
  </div>
</template>
<script>
import router from './router';
import EventBus, { ACTIONS } from './eventbus/index.js';
export default {
  name: "App",

  data: () => ({
    snackbar: {
        color: "#B71C1C",
        text: null,
        timeout: 7500,
        visible: false
    },
    text_color: "black"  
  }),
  created() {    
    const token = localStorage.getItem('token');
    if (token && this.$user == null)
    {
      router.push(`/?redirectUrl=${this.$route.path}`);
    }
  },
  mounted() {
    EventBus.$on(ACTIONS.SNACKBAR, (message, level) => {
      this.snackbar = {
            color: "#B71C1C",
            timeout: 6000,
            text: message,
            visible: true
          };      
      switch (level) {
        case "error":
          this.text_color = "white";
          this.snackbar.color = "#B71C1C";
          break;
        case "success":
          this.text_color = "white";
          this.snackbar.color = "#4CAF50";
          break;
        case "info":
          this.text_color = "white";
          this.snackbar.color = "#2196F3";
          break;
        case "warning":
          this.text_color = "black";
          this.snackbar.color = "#FFC107";
          break;
        default:
          this.text_color = "white";
          this.snackbar.color = "#B71C1C";
          break;
      }
    });
  },
};
</script>
