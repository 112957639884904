export default {
    updateColumnsBySettings() {
      if (!this.columnDefs) return;
      this.columnDefs.forEach((columnDef) => {
        const x = this.columnSettings.columnInfos.find(
          (c) => c.name == columnDef.columnName
        );
        if (x != null) {
          columnDef.hide = x != null && x.visible != true;
          columnDef.width = x.width;
        }
      });
      const dataGrid = this.$refs.dataGrid;
      if (dataGrid) {
        dataGrid.gridOptions.suppressPropertyNamesCheck = true;
        dataGrid.api.setGridOption('columnDefs', this.columnDefs);
      }
    },
  
    updateHeaders() {
      this.updateColumns();
      this.$refs.dataGrid.api.refreshCells();
    },
  
    //Updates localizations (e.g. after language changed)
    //and maybe also other columnDef changes to the columns (columnDef changes are not automatically updated)
    updateColumns() {
      const me = this;
      if (!me.columnDefs) return;
      me.columnDefs.forEach((columnDef) => {
        columnDef.headerName = this.$t(columnDef.columnName);
      });
      const dataGrid = me.$refs.dataGrid;
      if (dataGrid) {
        dataGrid.gridOptions.suppressPropertyNamesCheck = true;
        dataGrid.api.setGridOption('columnDefs', this.columnDefs);
      }
    },
  };
    