<template>
  <v-app>
    <v-container fill-height fluid>
      <v-row align="center" justify="center">
        <v-progress-circular
          indeterminate
          size="128"
          color="primary"
        ></v-progress-circular>
      </v-row>
    </v-container>   
  </v-app>
</template>
<style lang="scss">
</style>
<script>
import axios from "axios";
// import api from '@/api.js';
import Vue from "vue";
import router from "./router";
import moment from "moment";

export default {
  name: "AppStart",

  components: {},

  mounted() {
    this.initAndRedirect();
  },

  data: () => ({
    waitText: "",
  }),

  computed: {},

  methods: {
    async initAndRedirect() {
      Vue.prototype.$api = this.createApi();
      Vue.prototype.$gus = await this.loadGlobalUserSettings();
      Vue.prototype.$gcs = await this.loadGlobalClientSettings();
      this.loadLastSynctime();

      this.$vuetify.theme.dark = this.$gus.data.darkMode;
      Vue.prototype.$gridTheme = this.gridThemeName =
        this.$gus.data.darkMode == true
          ? "ag-theme-alpine-dark"
          : "ag-theme-alpine";
      this.$i18n.locale = this.$gus.data.languageCode;
      this.apiUrl = process.env.VUE_APP_BASE_URL;
      Vue.prototype.$user = await this.loadUser();
      await this.loadUserCollections();

      Vue.prototype.$dates = Vue.observable({
        mondayDate: new moment().startOf("isoWeek"),
        currentDate: new moment(),
      });
      Vue.prototype.$currentUserUID = Vue.prototype.$user.UserUID;
      const redirectUrl = this.$route.query.redirectUrl;
      const url = redirectUrl && redirectUrl != "/" ? redirectUrl : "/app";
      router.push(url);
    },

    async loadUser() {
      const res = await this.$api.get("user");
      localStorage.setItem("userUID", res.data.UserUID);
      return res.data;
    },

    hasRole(roleName) {
      const user = Vue.prototype.$user;
      if (!user) return false;
      if (!user.Roles) return false;
      return user.Roles.map((x) => x.RoleName).indexOf(roleName) >= 0;
    },

    async loadUserCollections() {
      const user = Vue.prototype.$user;
      if (this.hasRole("Controller")) {
        const res = await this.$api.get(`Users`);
        Vue.prototype.$allUsers = res.data;
      }
      if (this.hasRole("Teamleader")) {
        let teamUsers = [];
        const res2 = await this.$api.get(
          `UserTeamMember?UserUID=${user.UserUID}&OnlyActiveUser=true`
        );
        res2.data.forEach((el) => {
          teamUsers.push({ UserUID: el.MemberUserUID, UserName: el.UserName });
        });
        Vue.prototype.$teamUsers = teamUsers;
      }
    },

    async loadGlobalUserSettings() {
      const settingName = "Global";
      const res = await this.$api.get(`UserSetting?settingName=${settingName}`);
      let setting = res.data;
      if (setting.SettingValue === null) {
        setting.data = {
          languageCode: localStorage.getItem("loginLanguage"),
          darkMode: localStorage.getItem("loginDarkMode") == true,
        };
      } else {
        setting.data = JSON.parse(setting.SettingValue);
      }
      return setting;
    },

    async loadLastSynctime() {
      const res = await this.$api.get("synctime");
      Vue.prototype.$lastSyncTime = moment(res.data);
    },

    async loadGlobalClientSettings() {
      const settingName = "Global";
      const res = await this.$api.get(
        `ClientSetting?settingName=${settingName}`
      );
      let setting = res.data;
      if (setting.SettingValue === null) {
        setting.data = {
          maxBookingText: 30,
        };
      } else {
        setting.data = JSON.parse(setting.SettingValue);
      }
      return setting;
    },

    createApi() {
      let api = axios.create({
        baseURL: process.env.VUE_APP_BASE_URL,
        timeout: 100000000,
        params: {}, // do not remove this, its added to add params later in the config
        headers: {
          common: {
            "Content-Type": "application/json",
            "accept-language": "de-DE",
          },
        },
      });
      this.setInterceptors(api);
      return api;
    },

    setInterceptors(api) {
      const me = this;
      api.interceptors.request.use((config) => {
        let token = null;
        try {
          // token = store.getters['auth/token'];
          // token = `Bearer ${token}`;
          token = localStorage.getItem("token"); // store.getters['auth/token'];
          if (token !== null) {
            config.headers.authorization = `Bearer ${token}`;
          }
        } catch (err) {
          console.log("Error getting auth/token");
          console.log(err);
        }

        return config;
      });

      api.interceptors.response.use(
        (response) => response,
        (error) => {
          console.log("axios.interceptors error:");
          console.log(error);
          console.log(error.data);
          console.log(error.response);
          if (error.response.status == 401) {
            //store.auth.dispatch('logout');
            router.push("/login");
          }
          if (error.response.status == 400) {
            //me.$alert( {text: me.$t("ErrorOccured400"), dialogMaxWidth: 300});
          }
          if (error.response.status == 500) {
            me.$alert(me.$t("ErrorOccured500"));
          }
          return Promise.reject(error);
        }
      );
    }, 
  },
};
</script>