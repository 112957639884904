<template>
  <div :title="title" v-if="isTotalValue==true">
    <b :style="style">{{ displayValue }}</b>
  </div>
  <div :title="title" v-else>
    <span :style="style">{{ displayValue }}</span>
    <img
      v-if="hasError"
      style="height: 16px; padding-left: 4px; padding-top: 6px"
      :title="title"
      src="./exclamation-mark.png"
      alt="Has Error"
    />
  </div>
</template>
<style lang="scss">
</style>
<script>
export default {
  data: () => ({
    isTotalValue: false,
    displayValue: null,
    oldValue: null,
    error: null,
    hasError: null,
    style: "",
  }),
  beforeMount() {
    this.displayValue = this.params.valueFormatted; //value
    this.isTotalValue = this.params.data.isTotalValue;
    this.oldValue = this.getOldValue();
    this.error = this.getErrorText();
    this.title = this.getTitle();
    this.hasError = this.getErrorText() != null;
    this.style = this.getStyle();
  },
  computed: {},
  methods: {
    getTitle() {
      let title = "";
      if (this.error) title += this.error + " ";
      if (this.oldValue) title += this.getOldValueText() + " ";
      return title;
    },

    getStyle() {
      if (this.error != null && this.error.length > 0) {
          return "border-bottom: 4px double red";
      }
      if (!this.oldValue) return "";
      if (this.$gus.data.darkMode) {
        return "background-color:#b22222;padding-left:4px;padding-right:4px";
      }
      return "background-color:#fc7676;padding-left:4px;padding-right:4px";
    },
    

    getOldValue() {
      return this.params.data[this.params.colDef.field + "_Old"];
    },
    getOldValueText() {
      return `${this.$t("OldValue")}: ${this.getOldValue()}`;
    },
    getErrorText() {
      return this.params.data[this.params.colDef.field + "_Error"];
    },
  },
};
</script>