import api from '@/api.js';
export default {

    async loadData() {
        const me = this;
        me.loadingTemplate = this.$t('ProcessingData');
        me.$refs.dataGrid.api.showLoadingOverlay();
        const res = await api.get('users');
        me.$refs.dataGrid.api.hideOverlay();
        me.data = res.data;
    },

}