<template>
  <v-container fluid>
    <v-row>
      <table class="px-4 mt-0 pt-0" style="min-height: 50px">
        <tr>
          <td>
            <span class="pr-4">{{ $t("MyTimeRecording") }}</span>
          </td>
          <td>
            <span class="mx-4" style="font-size: 13px">{{ title }}</span>
          </td>
        </tr>
      </table>
    </v-row>
    <p :style="getStyle">
      <ag-grid-vue
        style="width: 100%; height: 100%"
        :class="gridThemeName"
        ref="dataGrid"
        rowSelection="single"
        :overlayNoRowsTemplate="noRowsTemplate"
        :overlayLoadingTemplate="loadingTemplate"
        :columnDefs="columnDefs"
        :rowData="items"
        :pinnedBottomRowData="totalItems"
        @rowClicked="onRowClicked($event)"
      ></ag-grid-vue>
    </p>
  </v-container>
</template>
<style lang="css">
@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-alpine.css";
.highlight-for-deletion {
  background-color: #ff6f00 !important;
}
</style>
<script>
import { AgGridVue } from "ag-grid-vue";
import ReadOnlyRenderer from "@/renderers/readOnlyRenderer.js";
import TimeRenderer from "@/renderers/timeRenderer.js";
import moment from "moment";
import api from "@/api.js";

export default {
  name: "time-recording-list",

  data: () => ({
    gridThemeName: "ag-theme-alpine-dark",
    items: [],
    totalItems: [],
    columnDefs: [],
    title: "",
    noRowsTemplate: null,
    loadingTemplate: null,
    mode: 1, //record worktime,
  }),

  async created() {},

  beforeMount() {
    this.initialize();
  },

  mounted() {},

  methods: {
    initialize() {
      this.title = `${moment().format("DD.MM.YYYY")} (${this.$t("Today")})`;
      this.noRowsTemplate = this.$t("NoRowsFound");
      this.loadingTemplate = this.$t("ProcessingData");
      this.setGridTheme(this.$gus.data.darkMode);
      this.columnDefs = this.createColumnDefs();
    },

    recordTime() {
      this.saveRecordTime();
    },

    stopTime() {
      this.savePauseTime();
    },

    canRecordSaveTime() {
      return true;
    },

    recordTime_isDisabled() {
      return this.mode == 1; //record pause
    },

    stopTime_isDisabled() {
      return this.mode == 0; //record worktime
    },

    setGridLanguage() {
      this.updateColumns();
    },

    updateColumns() {
      const me = this;
      if (!me.columnDefs) return;
      me.columnDefs.forEach((columnDef) => {
        columnDef.headerName = this.$t(columnDef.columnName);
      });
      const dataGrid = me.$refs.dataGrid;
      if (dataGrid) {
        dataGrid.gridOptions.suppressPropertyNamesCheck = true;
        dataGrid.api.setGridOption('columnDefs', this.columnDefs);
      }
    },

    async refresh() {
      const me = this;

      let url = `TimeCard/latest?userUID=${this.$user.UserUID}&onlyActive=true`;
      let res = await api.get(url);
      me.mode = res.data == null ? 0 : res.data.TrackType == 1 ? 0 : 1;
      this.$emit("ModeChanged", me.mode);

      url = `TimeCard/Day?DayDate=${moment().format("YYYY-MM-DD")}&userUID=${
        this.$user.UserUID
      }&onlyActive=true`;
      res = await api.get(url);

      res.data.Records.forEach(
        (e) =>
          (e.TrackTypeText = me.$t(e.TrackType == 0 ? "Worktime" : "Breaktime"))
      );
      this.items = res.data.Records;
      res.data.Totals.forEach(
        (e) =>
          (e.TrackTypeText =
            me.$t(e.TrackType == 0 ? "TotalWorktime" : "TotalBreaktime") + ":")
      );
      this.totalItems = res.data.Totals;
    },

    async saveRecordTime() {
      const now = moment().local();
      const isDst = now.isDST();
      let tzOffsetHours = isDst == true ? 2 : 1;

      const o = {
        TrackedTime: now,
        TrackedTimeText: now.format("YYYY-MM-DD H:m:s"),
        TZOffset: tzOffsetHours,
        DeviceID: this.$user.WebDeviceID,
        TrackType: 0,
        IsDeleted: false,
      };
      await api.post("TimeCard", o);
      this.refresh();
    },

    async savePauseTime() {
      const now = moment().local();
      const isDst = now.isDST();

      let tzOffsetHours = isDst == true ? 2 : 1;
      const o = {
        TrackedTime: now,
        TrackedTimeText: now.format("YYYY-MM-DD H:m:s"),
        TZOffset: tzOffsetHours,
        DeviceID: this.$user.WebDeviceID,
        TrackType: 1,
        IsDeleted: false,
      };
      await api.post("TimeCard", o);
      this.refresh();
    },

    setGridTheme(darkMode) {
      this.gridThemeName =
        darkMode == true ? "ag-theme-alpine-dark" : "ag-theme-alpine";
    },

    createColumnDefs() {
      const me = this;
      let columnDefs = [];
      columnDefs.push(
        me.createColumnDef(
          "TrackTypeText",
          "TrackType",
          me.$t("TrackType"),
          200
        )
      );

      let fromColumnDef = this.createColumnDef(
        "FromTimeText",
        "From",
        me.$t("From"),
        100
      );
      //fromColumnDef.cellRenderer = "timeRenderer";
      columnDefs.push(fromColumnDef);

      let toColumnDef = this.createColumnDef(
        "ToTimeText",
        "To",
        me.$t("To"),
        100
      );
      //toColumnDef.cellRenderer = "timeRenderer";
      columnDefs.push(toColumnDef);

      columnDefs.push(
        me.createColumnDef(
          "DurationHours",
          "Hours",
          me.$t("Hours"),
          100,
          "rightAligned"
        )
      );
      columnDefs.push(
        me.createColumnDef(
          "DurationMinutes",
          "Minutes",
          me.$t("Minutes"),
          100,
          "rightAligned"
        )
      );

      return columnDefs;
    },

    createColumnDef(field, columnName, headerName, width, type) {
      const columnDef = {
        field: field,
        columnName: columnName,
        headerName: headerName,
        editable: false,
        width: width,
        resizable: true,
      };
      if (type) {
        columnDef.type = type;
      }
      return columnDef;
    },
  },

  computed: {
    getStyle() {
      //magic number 200 is the height of the toolbar + footer
      //TODO: Calculate size dynamically instead of using a fixed magic number
      let subtractHeight = this.displayAlert ? 205 : 198;
      return (
        "width:100%;margin-top:10px;height:" +
        (parseInt(this.$vssHeight) - subtractHeight) +
        "px"
      );
    },
  },
  components: {
    AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    readOnlyRenderer: ReadOnlyRenderer,
    // eslint-disable-next-line vue/no-unused-components
    timeRenderer: TimeRenderer,
  },
};
</script>
