import moment from "moment";
import api from "@/api.js";

export default {
  initCssClassRules() {
    const me = this;
    me.rowClassRules = {
      "highlight-for-deletion": "data.HasError == true",
      "highlight-row-for-change": "data.HasChanges == true",
    };
    me.valueCellClassRules = {
      normal: function(params) {
        return me.useCssStyle(params, "Normal", me.getStatusByParams);
      },
      finalizedDark: function(params) {
        if (me.$gus.data.darkMode === false) return false;
        return me.useCssStyle(params, "Finalized", me.getStatusByParams);
      },
      bookingDark: function(params) {
        if (me.$gus.data.darkMode === false) return false;
        return me.useCssStyle(params, "Booking", me.getStatusByParams);
      },
      bookedDark: function(params) {
        if (me.$gus.data.darkMode === false) return false;
        return me.useCssStyle(params, "Booked", me.getStatusByParams);
      },
      finalizedBright: function(params) {
        if (me.$gus.data.darkMode === true) return false;
        return me.useCssStyle(params, "Finalized", me.getStatusByParams);
      },
      bookingBright: function(params) {
        if (me.$gus.data.darkMode === true) return false;
        return me.useCssStyle(params, "Booking", me.getStatusByParams);
      },
      bookedBright: function(params) {
        if (me.$gus.data.darkMode === true) return false;
        return me.useCssStyle(params, "Booked", me.getStatusByParams);
      },
      "highlight-for-change": function(params) {
        const hasChangesFieldName = params.colDef.field + "_HasChanges";
        return params.data[hasChangesFieldName] === true;
      },
    };
  },

  deepClone(o) {
    return JSON.parse(JSON.stringify(o));
  },

  getStatusByParams(params) {
    return this.getStatus(params.colDef.weekdayNo);
  },

  getStatus(weekDay) {
    const me = this;

    if (me.showOnlyOneDay === true) {
      if (!weekDay) {
        weekDay = this.$dates.currentDate.day() - 1;
        if (weekDay == -1) weekDay = 6; //sunday exception
      }
      const status = this.getStatusByWeekDay(
        weekDay,
        me.data.WeekState.DayStatus
      );
      return status;
    }

    const defaultStatus = "Normal"; //Normal edit mode
    if (me.data === null || me.data.WeekState === null || me.data.WeekState.Status === null)
    {
      return defaultStatus;
    }

    if (!weekDay) 
    {
      return me.data.WeekState.Status; //not weekday dependend state (whole week)
    }

    const status = this.getStatusByWeekDay(
      weekDay,
      me.data.WeekState.DayStatus
    );
    
    return status;
  },

  getStatusByWeekDay(weekDayNo, dayStatus)
  {
    const defaultStatus = "Normal";

    if (!dayStatus)
      return defaultStatus;

    if (!dayStatus[weekDayNo])
      return defaultStatus;

    // See the enum WorkStateEnum
    switch (dayStatus[weekDayNo]) {
      case 0: return defaultStatus;
      case 1: return "Finalized";
      case 2: return "Booking";
      case 3: return "Booked";
    }
  },

  getStatusByWeekDayOld(weekDayNo, statusToDate, status) {
    const checkDate = moment(this.$dates.mondayDate).add(weekDayNo, "days");
    return this.getStatusByDate(checkDate, statusToDate, status);
  },

  getStatusByDate(checkDate, statusToDate, status) {
    const defaultStatus = "Normal"; //Normal edit mode
    const statusActive = moment(checkDate) <= moment(statusToDate);
    if (statusActive == false) {
      return defaultStatus;
    }
    return status;
  },

  useCssStyle(params, status, cb) {
    if (status == "Normal") {
      if (params.node.rowPinned) return true;
      //if (!params.value || params.value == 0) return true;
    } else {
      if (params.node.rowPinned) return false;
      //if (!params.value || params.value == 0) return false;
    }
    const statusCompare = cb(params);
    return status == statusCompare;
  },

  hasRole(roleName) {
    return this.hasUserRole(this.$user, roleName);
  },

  hasRoles(roleNames) {
    return this.hasUserRoles(this.$user, roleNames);
  },

  hasRolesCsv(roleNamesCsv) {
    return this.hasUserRolesCsv(this.$user, roleNamesCsv);
  },

  hasUserRolesCsv(user, roleNamesCsv) {
    const roleNames = roleNamesCsv.split(",");
    return this.hasUserRoles(user, roleNames);
  },

  hasUserRoles(user, roleNames) {
    if (!user) return false;
    let hasRole = false;
    roleNames.forEach((roleName) => {
      if (this.hasUserRole(user, roleName)) {
        hasRole = true;
        return;
      }
    });
    return hasRole;
  },

  hasUserRole(user, roleName) {
    if (!user) return false;
    if (!user.Roles) return false;
    return user.Roles.map((x) => x.RoleName).indexOf(roleName) >= 0;
    // let canSelect = false;
    // (this.$user.Roles).forEach(role => {
    //     if (roles.indexOf(role.RoleName) >= 0) canSelect = true;
    // });
    // return canSelect;
  },

  getTotalWorkCaption() {
    return `${this.$t("Totals")} ${this.$t("Workhours")}:`;
  },

  getTotalExpensesCaption() {
    return `${this.$t("Totals")} ${this.$t("Expenses")}:`;
  },

  updateSettingsByColumns() {
    const dataGrid = this.$refs.dataGrid;
    if (!dataGrid) this.return;

    if (!this.columnSettings) {
      this.columnSettings = {
        columnInfos: [],
      };
    }
    this.columnSettings.headerColumnCount = 0;
    this.columnSettings.firstField = null;
    const columnDefs = dataGrid.api.getColumnDefs();
    if (!columnDefs) return;
    
    columnDefs.forEach((columnDef) => {
      const column = dataGrid.api.getColumn(columnDef.colId);
      let columnInfo = this.columnSettings.columnInfos.find(
        (c) => c.name == columnDef.columnName
      );
      if (columnInfo == null) {
        columnInfo = {
          name: columnDef.columnName,
          width: column.actualWidth,
          visible: column.visible,
          field: columnDef.field,
          isHeaderColumn: columnDef.isHeaderColumn,
        };
        this.columnSettings.columnInfos.push(columnInfo);
      } else {
        columnInfo.width = column.actualWidth;
        columnInfo.visible = column.visible;
      }
      if (this.columnSettings.firstField == null && !columnDef.hide)
        this.columnSettings.firstField = columnDef.field;
      if (columnDef.isHeaderColumn && !columnDef.hide)
        this.columnSettings.headerColumnCount++;
    });
  },

  getHeaderColSpan(params) {
    return params.node.rowPinned &&
      params.colDef.field === this.columnSettings.firstField
      ? this.columnSettings.headerColumnCount
      : 1;
  },

  getDefaultRenderer(params) {
    if (params.node.rowPinned) {
      return {
        component: "boldRenderer",
      };
    } else {
      // rows that are not pinned don't use any cell renderer by default
      return undefined;
    }
  },

  addRowValuesToTotalRow(row, totalRow, factor, workCategoryID) {
    const me = this;
    totalRow.MondayValue += me.toDecimal(row.MondayValue) * factor;
    totalRow.TuesdayValue += me.toDecimal(row.TuesdayValue) * factor;
    totalRow.WednesdayValue += me.toDecimal(row.WednesdayValue) * factor;
    totalRow.ThursdayValue += me.toDecimal(row.ThursdayValue) * factor;
    totalRow.FridayValue += me.toDecimal(row.FridayValue) * factor;
    totalRow.SaturdayValue += me.toDecimal(row.SaturdayValue) * factor;
    totalRow.SundayValue += me.toDecimal(row.SundayValue) * factor;
    totalRow.TotalsValue += me.toDecimal(row.TotalsValue) * factor;
    totalRow.WorkCategoryID = workCategoryID;
    totalRow.isTotalValue = true;
  },

  isDayEditable(params, weekdayNo) {
    if (weekdayNo == -1) weekdayNo = 6; //sunday exception
    if (params.node.rowPinned) return false;

    let statusName = "Normal";
    if (!params.data.WeekState)
      statusName = this.getStatus(weekdayNo);
    else
      statusName = this.getStatusByWeekDay(weekdayNo, params.data.WeekState.DayStatus)
      
    return this.isEditableForStatus(statusName);
  },

  isHeaderColumnEditable(params, selectedUserUID) {
    //if selectedUserUID is passed then "isUserInTeam" will be checked as well
    const me = this;
    if (params.node.rowPinned) return false;
    const isUserInTeam = me.isUserInTeam(selectedUserUID);
    if (me.hasRolesCsv("Teamleader,Typist") && isUserInTeam) return true;
    return false;
  },

  isUserInTeam(userUID) {
    if (!userUID) return true; //in that case it should not be checked
    if (userUID == this.$user.UserUID) return true; //Allow for himself
    if (!this.$teamUsers) return false;
    if (this.$teamUsers.map((x) => x.UserUID).includes(userUID)) return true;
    return false;
  },

  isPartlyWeekStatusGreater() {
    const finalDayOfWeekDay = this.$dates.mondayDate.clone().add(6, "days");
    const lowerThanEnd =
      moment(this.data.WeekState.StatusToDate) < finalDayOfWeekDay;

    //currentDate without time
    var currentDate = moment({
      year: this.$dates.currentDate.year(),
      month: this.$dates.currentDate.month(),
      day: this.$dates.currentDate.date(),
    });
    const greaterCurrent =
      currentDate > moment(this.data.WeekState.StatusToDate);
    return greaterCurrent && lowerThanEnd;
  },

  isPartlyWeekStatusLowerEqual() {
    //currentDate without time
    var currentDate = moment({
      year: this.$dates.currentDate.year(),
      month: this.$dates.currentDate.month(),
      day: this.$dates.currentDate.date(),
    });
    var statusToDate = moment(this.data.WeekState.StatusToDate);
    const lowerEqualCurrent = currentDate <= statusToDate;
    return lowerEqualCurrent;
  },

  isWeekEditable(params) {
    if (params.node.rowPinned) return false;
    const statusName = this.getStatus();
    const editable =
      !params.node.rowPinned && this.isEditableForStatus(statusName); //Total-Row
    return editable;
  },

  isEditableForStatus(statusName) {
    //TODO: Check if user is in the team

    if (statusName == "Normal" && this.hasRolesCsv("Typist,Teamleader"))
      return true;

    if (statusName == "Finalized" && this.hasRolesCsv("Teamleader,Controller"))
      return true;
    return false;
  },

  cleanupEmptyNewValues() {
    if (!this.data.Rows) return;
    this.data.Rows.forEach((row) => {
      if (row.Values)
        row.Values = row.Values.filter(
          (val) =>
            val.WorkUID != null || (val.WorkUID == null && val.Value != 0)
        );
    });
  },

  async getTeamUserUIDs() {
    const me = this;
    const controllerRole = this.$user.Roles.find((r) => r.RoleID == 2);
    let userUIDs = "";
    let res;
    if (controllerRole) {
      res = await api.get("users");
      res.data.forEach((user) => {
        userUIDs += "UserUIDs=" + user.UserUID + "&";
      });
    } else {
      const teamLeaderRole = this.$user.Roles.find((r) => r.RoleID == 3);
      if (teamLeaderRole) {
        res = await api.get(`userTeamMember?UserUID=${me.$user.UserUID}`);
        res.data.forEach((user) => {
          userUIDs += "UserUIDs=" + user.MemberUserUID + "&";
        });
      }
    }
    if (userUIDs && userUIDs.length > 0) userUIDs = userUIDs.slice(0, -1);
    return userUIDs;
  },
};