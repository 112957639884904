<template>
    <v-row justify-content="left" class="pa-0 ma-0">
        <span>{{displayValue}}</span><span v-if="isLongText()">
                <v-btn class="ml-2 mr-4" 
                    :style="getButtonStyle()"
                    elevation="2"
                    small icon color="primary"
                    @click="showDialog($event)">
                    <v-icon small dark>mdi-text-box</v-icon>              
                </v-btn></span>
            
        <v-dialog overlay-color="blue darken-3"
        v-model="dialog"
        scrollable
        max-width="700px"
        >
        <v-card>
            <v-card-title>
            <v-icon>mdi-text-box</v-icon>
            <span class="ml-2">{{caption}}</span>
            <v-spacer></v-spacer>
            <v-menu
                bottom
                left
            >
                <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    @click="dialog = false"
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                </template>
            </v-menu>
            </v-card-title>          
            <v-card-text style="height: 390px;">
            <v-container>
                <v-textarea
                    ref="vtextarea"
                    outlined
                    rows="10"
                    :counter="$gcs.data.maxBookingText"
                    v-model="valTextarea"
                    readonly
                    ></v-textarea>            
            </v-container>
            </v-card-text>
            
            <v-card-actions>
            
            <v-btn
                color="standard"
                @click="dialog = false"
            >
                {{$t('OK')}}
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </v-row> 
</template> 
<style lang="scss">
</style>
<script>
import Vue from "vue";
export default {
    data: () => ({
        displayValue: null,
        valTextarea: null,
        dialog: false,
        caption: ''
    }),
    methods: {
        getButtonStyle() {
            if (this.$gus.data.darkMode) return "background:#444";
            return "background:#F5F5F5";
        },
        isLongText() {
            if (this.displayValue && this.displayValue.length > 30)
                return true;
            
            return false;
        },
        showDialog() {
            const me = this;
            me.dialog = true;
            Vue.nextTick(() => {
                setTimeout(() => {
                    me.$refs.vtextarea.focus();
                }, 50);
            });
        },        
    },
    beforeMount() {
        if (this.params.value)
        {
            if (this.params.value.length > 30)
            {
                this.displayValue = this.params.value.substring(0, 30) + "...";
            }
            else
            {
                this.displayValue = this.params.value;
            }
            this.valTextarea = this.params.value;
        }
    },
    mounted() {
        const me = this;
        me.caption = me.params.colDef.headerName;
    }
 };
</script>