import api from "@/api.js";
export default {
  async loadData() {
    const me = this;
    me.loadingTemplate = this.$t("ProcessingData");
    me.$refs.dataGrid.api.showLoadingOverlay();

    me.updatePeriodText();

    const res = await api.get(
      `workoverview/week?mondayDate=${me.$dates.mondayDate.format(
        "YYYY-MM-DD"
      )}`
    );
    let data = res.data;
    data.forEach((row) => {
      row.DayEntries = me.getDayEntries(row);
      row["LinkToEmployeeWeeklyReport"] = `employeeWeeklyReport/${row.UserUID}`;
      if (row.ProjectKeys != null)
      {
        row["LinkToConstructionSiteReport"] = `constructionSiteReport/${row.UserUID}?projectUID=${row.ProjectUID}`;        
      }
      else
      {
        row["LinkToConstructionSiteReport"] = `constructionSiteReport/${row.UserUID}`;
      }
    });

    me.$refs.dataGrid.api.hideOverlay();
    me.data = data;
  },

  updatePeriodText() {
    const me = this;
    const locale = this.$i18n.locale;
    const weekStartDateFormatted = me.$dates.mondayDate
      .locale(locale)
      .format("dd DD. MMM YYYY");
    const weekEndDateFormatted = me.$dates.mondayDate
      .clone()
      .add(6, "days")
      .locale(locale)
      .format("dd DD. MMM YYYY");
    me.periodText = `${weekStartDateFormatted} .. ${weekEndDateFormatted}`;
  },

  getDayEntries(row) {
    const me = this;
    let s = me.getFormattedDayCount("Monday", row);
    s += me.getFormattedDayCount("Tuesday", row);
    s += me.getFormattedDayCount("Wednesday", row);
    s += me.getFormattedDayCount("Thursday", row);
    s += me.getFormattedDayCount("Friday", row);
    s += me.getFormattedDayCount("Saturday", row);
    s += me.getFormattedDayCount("Sunday", row);
    if (s != null && s.length > 2) {
      s = s.substring(0, s.length - 2);
    }
    return s;
  },

  getFormattedDayCount(weekDay, row) {
    let count = row[weekDay + "Count"] == null ? 0 : row[weekDay + "Count"];
    if (count == 0) return "";
    return `${this.$t("Column_WorkHours" + weekDay)} ${count} | `;
  },
};
