<template>
  <v-container fluid>
    <v-row>
      <table class="px-4 mt-0 pt-0" style="min-height:50px">
        <tr>
          <td>
            <span class="pr-4">{{title}}</span>
          </td>
          <td>
            <span class="mx-4" style="font-size:13px">{{periodText}}</span>
          </td>
        </tr>
      </table>
    </v-row>
    <p :style="getStyle">
      <ag-grid-vue style="width: 100%;height:100%" 
          :class="gridThemeName"                    
          :gridOptions="gridOptions"
          ref="dataGrid"          
          rowSelection="single"
          :columnDefs="columnDefs"
          :rowData="data"
          :overlayNoRowsTemplate="noRowsTemplate"
          :overlayLoadingTemplate="loadingTemplate"
          @rowClicked="onRowClicked($event)"
      >
      </ag-grid-vue>
    </p>    
    <column-selection-dialog ref="columnSelectionDialog" 
      @columnSelectionChanged="onColumnSelectionChanged($event)"
      @columnSelectionOK="onColumnSelectionOK($event)"
      >
    </column-selection-dialog>
    <day-or-week-selection-dialog ref="dayOrWeekSelectionDialog"  @onOK="onWeekdaySelectionChanged($event)"></day-or-week-selection-dialog>
</v-container>
</template>
<style lang="scss">
   @import "~ag-grid-community/styles/ag-grid.css";
   @import '~ag-grid-community/styles/ag-theme-alpine.css';

</style>
<script>
import DayOrWeekSelectionDialog from '@/components/dialogs/DayOrWeekSelectionDialog/_Index.vue';
import ColumnSelectionDialog from '@/components/dialogs/ColumnSelectionDialog/_Index.vue';
import ReadOnlyRenderer from '@/renderers/readOnlyRenderer.js';
import LinkRenderer from '@/renderers/linkRenderer.vue';
import WeekStatusRenderer from '@/renderers/weekStatusRenderer.vue'
import LinkRendererConstructionSiteReport from '@/renderers/linkRendererConstructionSiteReport.vue';
import WorkRenderer from '@/renderers/workRenderer.vue';
import ExpensesRenderer from '@/renderers/expensesRenderer.vue';
import BoldRenderer from '@/renderers/boldRenderer.js';
import FormattedBoldRenderer from '@/renderers/formattedBoldRenderer.js';
import actions from '../WeekOverviewList/actions.js';
import { AgGridVue } from "ag-grid-vue";  
import dataGridInit from '../WeekOverviewList/dataGridInit.js';
import loadData from '../WeekOverviewList/loadData.js';
import dataGridUpdate from '../WeekOverviewList/dataGridUpdate.js';
import eventHandlers from '../WeekOverviewList/eventHandlers.js';
import utils from '../../../common/utils';

  export default {
    
    name: 'week-overview-list',

    props: {
      title: String,
      userSettingsKey: String,
    },    
    
    data: () => ({
      currentUser: null,
      showDividers: true,
      gridThemeName: 'ag-theme-alpine-dark',
      defaultColumnDefs: null,
      columnDefs: null,
      columnSettings: null,
      data: [],
      themeName: '',
      rowClassRules: null,
      noRowsTemplate: null,
      loadingTemplate: null,
      undoStack: [],
      settings: {},
      periodText: '',
      gridOptions: null      

    }),

    methods: {
      ...actions,
      ...dataGridInit,
      ...dataGridUpdate,
      ...loadData,
      ...eventHandlers,
      ...utils
    },

    async created() {
      this.setGridTheme(this.$gus.data.darkMode);
      this.gridOptions = {
        context: {
            componentParent: this
        }      
      }
    },

    async beforeMount() {
      this.initialize();
    },

    computed:
    {
      getStyle() {
        //magic number 200 is the height of the toolbar + footer
        //TODO: Calculate size dynamically instead of using a fixed magic number
        let subtractHeight = this.displayAlert ? 265 : 190;
        return "width:100%;margin-top:10px;height:" + (parseInt(this.$vssHeight) - subtractHeight) + "px";
      },
    },
    components: {
      AgGridVue,
      // eslint-disable-next-line vue/no-unused-components
      readOnlyRenderer: ReadOnlyRenderer,
      // eslint-disable-next-line vue/no-unused-components
      weekStatusRenderer: WeekStatusRenderer,
      // eslint-disable-next-line vue/no-unused-components
      linkRenderer: LinkRenderer,
      // eslint-disable-next-line vue/no-unused-components
      linkRendererConstructionSiteReport: LinkRendererConstructionSiteReport,
      // eslint-disable-next-line vue/no-unused-components
      workRenderer: WorkRenderer,
      // eslint-disable-next-line vue/no-unused-components
      expensesRenderer: ExpensesRenderer,
      // eslint-disable-next-line vue/no-unused-components
      boldRenderer: BoldRenderer,
      // eslint-disable-next-line vue/no-unused-components
      formattedBoldRenderer: FormattedBoldRenderer,

      // eslint-disable-next-line no-undef
      ColumnSelectionDialog,

      DayOrWeekSelectionDialog      
    },
  }
</script>
