//import api from '@/api.js';
import moment from "moment";
import apiExcel from "@/apiExcel.js";
import fileSaver from "file-saver";
import { showSnackbar } from "@/common/globalActions";

export default {
  async initialize() {
    const me = this;

    me.selectedUserUID = me.$route.params.userUID;
    if (!me.selectedUserUID) {
      me.selectedUserUID = me.$currentUserUID;
    }
    me.selectedUserUID = me.selectedUserUID.toLowerCase();
    await me.loadReferenceData();
    await me.loadUserSettings();
    await this.initDataGrid();

    const dataGrid = this.$refs.dataGrid;
    if (dataGrid) {
      dataGrid.gridOptions.suppressPropertyNamesCheck = true;
      dataGrid.gridOptions.undoRedoCellEditing = true;
    }
    this.noRowsTemplate = this.$t("NoRowsFound");
    this.updateColumnsBySettings();
    this.updateTotalHeaders();
    this.refresh();
  },

  async refresh() {
    const me = this;
    const dataGrid = this.$refs.dataGrid;
    if (!dataGrid) return;
    const cell = dataGrid.api.getFocusedCell();
    me.updateColumns();
    
    await me.loadData();
    if (dataGrid) {
      dataGrid.api.setGridOption('rowData', this.data.Rows);
      dataGrid.api.setGridOption('pinnedBottomRowData', this.totalsRowData);
    }
    this.updateTotals();
    if (cell) {
      dataGrid.api.setFocusedCell(cell.rowIndex, cell.column);
    }
  },

  canRelease() {
    const me = this;
    if (
      !(
        me.hasRoles(["Typist", "Controller"]) ||
        (me.hasRoles(["Teamleader"]) && me.isUserInTeam(me.selectedUserUID))
      )
    )
      return false;

    const status = me.getStatus();
    
    //const isPartly = me.isPartlyWeekStatusGreater();

    return status === "Normal";
    //return status === "Normal" || (status === "Finalized" && isPartly);
  },

  canReturn() {
    const me = this;
    if (!me.hasRoles(["Controller"])) return false;

    const status = me.getStatus();
    if (status !== "Finalized") return false;

    if (this.showOnlyOneDay === true) return true;
    return me.isPartlyWeekStatusLowerEqual();

    //old logic
    // hasRoles(['Controller']) &&
    // getStatus() == 'Finalized' &&
    // showOnlyOneDay == false
  },

  canBook() {
    const me = this;
    if (!me.hasRoles(["Controller"])) return false;

    const status = me.getStatus();
    if (status !== "Finalized") return false;

    //const isPartly = me.isPartlyWeekStatusLowerEqual();
    if (this.showOnlyOneDay === true) return true;
    return me.isPartlyWeekStatusLowerEqual();
    //old:
    // hasRoles(['Teamleader', 'Controller']) &&
    // getStatus() == 'Finalized' &&
    // showOnlyOneDay == false
  },

  insertNewRow() {
    const me = this;
    const dataGrid = this.$refs.dataGrid;
    if (dataGrid) {
      let cell = dataGrid.api.getFocusedCell();
      if (cell == null) {
        return;
      }
      this.data.Rows.splice(cell.rowIndex + 1, 0, me.createNewRow());
      dataGrid.api.applyTransaction({ add: [this.data.Rows[cell.rowIndex + 1]], addIndex: cell.rowIndex + 1 });
    }
  },

  createNewRow() {
    return {
      ProjectUID: null,
      WorkTypeUID: null,
      PhaseUID: null,
      StructureUID: null,
      CostCenterID: null,
      BookingText: null,
      MondayValue: null,
      TuesdayValue: null,
      WednesdayValue: null,
      ThursdayValue: null,
      FridayValue: null,
      SaturdayValue: null,
      SundayValue: null,
      TotalsValue: null,
      ExpenseSuffix: "",
      WorkHourSuffix: "",
    };
  },

  undo() {
    const me = this;
    let lastVal = this.undoStack.pop();
    if (lastVal) {
      lastVal.data[lastVal.fieldName] = lastVal.oldValue;
      lastVal.data.changeCount--;
      lastVal.data.HasChanges = lastVal.data.changeCount > 0;
      const hasChangesFieldName = lastVal.fieldName + "_HasChanges";
      lastVal.data[hasChangesFieldName] = false;
      const dataGrid = this.$refs.dataGrid;
      if (dataGrid) {
        dataGrid.api.redrawRows();
      }
      this.updateTotals();
    }
    me.$emit("IsDirtyChanged", me.isDirty());
  },

  isDirty() {
    return this.undoStack != null && this.undoStack.length > 0;
  },

  setWeekDayValueInRow(row, weekDayValue) {
    if (row.Values == null || row.Values.length == 0) return false;
    let found = false;
    row.Values.forEach((value) => {
      if (!value) return false;
      if (value.DayOfWeek == weekDayValue.DayOfWeek) {
        value.Value = weekDayValue.Value;
        found = true;
        return true;
      }
    });
    return found;
  },

  async save(nextPageCallback) {
    const me = this;
    let changedData = {};
    Object.assign(changedData, me.data);
    changedData.Rows = me.data.Rows.filter((x) => x.HasChanges === true);
    changedData.Rows.forEach((row) => {
      if (row.OldKeyValues == null || row.OldKeyValues.WorkTypeUID == null) {
        row.OldKeyValues = null;
      }
      if (!row.Values) row.Values = [];
      me.undoStack.forEach((changedValue) => {
        if (changedValue.weekDayValue != null && changedValue.data == row) {
          if (!me.setWeekDayValueInRow(row, changedValue.weekDayValue)) {
            row.Values.push(changedValue.weekDayValue);
          }
        }
      });
    });
    me.cleanupEmptyNewValues();
    const errorCode = this.validateChangedData(changedData);
    if (errorCode != null) {
      this.$alert(this.$t(errorCode));
      return;
    }

    me.setRowKeysIfNeeded(changedData);

    const res = await this.$api.post("workweek", changedData);

    let hasErrors = false;
    let successCount = 0;

    res.data.RowResults.forEach((rowResult) => {
      if (rowResult.HasError) {
        hasErrors = true;
      }
      let row = me.findRow(rowResult.RowKey);
      rowResult.DayResults.forEach((dayResult) => {
        if (dayResult.Result == "failed") {
          row[dayResult.DayOfWeek + "Value_Error"] = dayResult.Error;
        } else {
          if (row[dayResult.DayOfWeek + "Value_Error"])
            delete row[dayResult.DayOfWeek + "Value_Error"];
          let x = row.Values.find((o) => o.DayOfWeek === dayResult.DayOfWeek);
          if (x) {
            x.WorkUID = dayResult.WorkUID;
          }
          successCount++;
        }
      });
    });
    if (hasErrors) {
      this.updateColumns();
      showSnackbar(this.$t(successCount > 0 ? "SavePartiallyFailedMessage" : "SaveFailedMessage"), "error");
    } else {
      if (me.$refs.dataGrid) me.$refs.dataGrid.api.hideOverlay();
      this.refresh();
      me.undoStack = [];
      me.$emit("IsDirtyChanged", false);
    }
    if (nextPageCallback && hasErrors == false) nextPageCallback();
  },

  setRowKeysIfNeeded(changedData) {
    changedData.Rows.forEach((row) => {
      if (!row.RowKey) {
        row.RowKey = Math.floor(Math.random() * Date.now()).toString();
      }
    });
  },

  validateChangedData(changedData) {
    let errorCode = null;
    changedData.Rows.forEach((row) => {
      if (row.WorkTypeUID === null) {
        errorCode = "Error_NoWorkTypeSpecified";
        return;
      }
    });
    return errorCode;
  },

  findRow(rowKey) {
    const x = this.data.Rows.filter((x) => x.RowKey == rowKey);
    if (x == null || x.length == 0) return null;
    return x[0];
  },

  delete() {
    const me = this;
    if (!me.isUserInTeam(me.selectedUserUID)) {
      me.showAlert(me.$t("Msg_NotAllowedToDeleteEntryForUser"), 3000);
      return;
    }

    const dataGrid = this.$refs.dataGrid;
    if (dataGrid) {
      let cell = dataGrid.api.getFocusedCell();
      let rowData = this.data.Rows[cell.rowIndex];
      if (!cell || !rowData) {
        this.showAlert(this.$t("Msg_NoRowSelectedForDeletion"), 3000);
        return;
      }
      const nodes = dataGrid.api.getRenderedNodes();
      if (rowData.WorkTypeUID == null) {
        this.deleteRow(dataGrid, rowData, cell, nodes);
        return;
      }
      rowData.HasError = true;
      dataGrid.api.redrawRows();
      me.$confirm(me.$t("Msg_Confirm_DeleteRow_Text"), {
        title: this.$t("Msg_Confirm_DeleteRow_Title"),
        buttonTrueText: me.$t("Yes"),
        buttonFalseText: me.$t("No"),
      }).then((res) => {
        if (res == true) {
          this.deleteRow(dataGrid, rowData, cell, nodes);
          rowData.HasError = false;
        } else {
          rowData.HasError = false;
          dataGrid.api.redrawRows();
        }
      });
    }
  },

  async deleteRow(dataGrid, rowData, cell, nodes) {
    if (!rowData.Values)
    {
      this.data.Rows.splice(cell.rowIndex, 1);
      dataGrid.api.applyTransaction({ remove: [rowData] });
      return;
    }
    const values = rowData.Values.filter((x) => x.WorkUID);
    if (!values) return;
    const workUIDs = values.map((x) => x.WorkUID);
    if (workUIDs && workUIDs.length > 0) {
      await this.$api.delete("workweek", { data: workUIDs });
    }

    //Quick solution. rowIndex cannot be used if data is sorted (TODO: use find-key instead)
    //Currently sorting is disabled, so no immedate action needed
    nodes[cell.rowIndex].setSelected(true);
    this.data.Rows.splice(cell.rowIndex, 1);
    dataGrid.api.applyTransaction({ remove: [rowData] });
  },

  async releaseForControl() {
    const me = this;
    const titleKey =
      this.showOnlyOneDay === true ? "ReleaseDayText" : "ReleaseWeekText";
    
    me.$confirm(this.$t(titleKey), {
      title: this.$t("ReleaseTitle"),
      buttonTrueText: this.$t("Yes"),
      buttonFalseText: this.$t("No"),
      icon: "mdi-comment-question-outline",
      color: "gray",
    }).then((res) => {
      if (res == true) {
        me.releaseForControlExec();
      }
    });
  },

  async releaseForControlExec() {
    const me = this;

    const dayDate =
      this.showOnlyOneDay == true
        ? moment(this.$dates.currentDate)
        : moment(this.$dates.mondayDate).add(6, "d");

    await this.$api.post("User/Finalize", {
      UserUID: me.selectedUserUID,
      DayDate: dayDate.format("YYYY-MM-DD"),
    });
    me.refresh();
  },

  async returnForProcessing() {
    const me = this;
    me.$confirm(this.$t("ReturnWeekText"), {
      title: this.$t("ReturnTitle"),
      buttonTrueText: this.$t("Yes"),
      buttonFalseText: this.$t("No"),
      icon: "mdi-comment-question-outline",
      color: "gray",
    }).then((res) => {
      if (res == true) {
        me.returnForProcessingExec();
      }
    });
  },

  async returnForProcessingExec() {
    const me = this;
    const dayDate =
      this.showOnlyOneDay == true
        ? moment(this.$dates.currentDate)
        : moment(this.$dates.mondayDate);    
    await this.$api.post("User/FinalizeReset", {
      UserUID: me.selectedUserUID,
      DayDate: dayDate.subtract(1, "d").format("YYYY-MM-DD"),
    });
    me.refresh();
  },

  async releaseForBooking() {
    const me = this;
    me.$confirm(this.$t("ReleaseWeekForBookingText"), {
      title: this.$t("ReleaseWeekForBookingTitle"),
      buttonTrueText: this.$t("Yes"),
      buttonFalseText: this.$t("No"),
      icon: "mdi-comment-question-outline",
      color: "gray",
    }).then((res) => {
      if (res == true) {
        me.releaseForBookingExec();
      }
    });
  },

  async releaseForBookingExec() {
    const me = this;
    const dayDate =
      this.showOnlyOneDay == true
        ? moment(this.$dates.currentDate)
        : moment(this.$dates.mondayDate).add(6, "d");
    await this.$api.post("User/Booking", {
      UserUID: me.selectedUserUID,
      DayDate: dayDate.format("YYYY-MM-DD"),
    });
    me.refresh();
  },

  navigateDateBackward() {
    if (this.showOnlyOneDay === true) {
      this.$dates.currentDate = this.$dates.currentDate.subtract(1, "d");
      this.$dates.mondayDate = this.$dates.currentDate
        .clone()
        .startOf("isoWeek");
    } else {
      this.$dates.mondayDate = this.$dates.mondayDate.subtract(1, "w");
      this.$dates.currentDate = this.$dates.mondayDate.clone();
    }
    this.refresh();
  },

  navigateDateForward() {
    if (this.showOnlyOneDay === true) {
      this.$dates.currentDate = this.$dates.currentDate.add(1, "d");
      this.$dates.mondayDate = this.$dates.currentDate
        .clone()
        .startOf("isoWeek");
    } else {
      this.$dates.mondayDate = this.$dates.mondayDate.add(1, "w");
      this.$dates.currentDate = this.$dates.mondayDate.clone();
    }
    this.refresh();
  },

  navigateToDate() {
    this.$refs.dayOrWeekSelectionDialog.show(
      moment(this.$dates.currentDate).format("YYYY-MM-DD"),
      this.showOnlyOneDay
    );
  },

  getFilenameFromHeader(responseHeaders, defaultName) {
    let fileName = defaultName;
    let content_disposition = responseHeaders["content-disposition"];
    if (content_disposition && content_disposition.indexOf(";") > 0) {
      let tokens = content_disposition.split(";");
      if (tokens && tokens.length > 1) {
        let temp = tokens[1].trim();
        tokens = temp.split("=");
        if (tokens && tokens.length > 1) {
          fileName = tokens[1].trim();
        }
      }
    }
    return fileName;
  },

  async exportExcelForSelectedUser(type) {
    const me = this;
    let language = me.$i18n.locale;
    const response = await apiExcel.get(
      `excel/${type}?UserUIDs=${me.selectedUserUID}&MondayDate=${moment(
        this.$dates.mondayDate
      ).format("YYYY-MM-DD")}&Language=${language}&sheetPerUser=true`
    );
    const fileName = me.getFilenameFromHeader(
      response.headers,
      `${me.title}.xlsx`
    );
    fileSaver.saveAs(response.data, fileName);
  },

  async exportExcelForAllUsers(type) {
    const me = this;
    let language = me.$i18n.locale;
    const url = `excel/${type}?MondayDate=${moment(
      this.$dates.mondayDate
    ).format("YYYY-MM-DD")}&Language=${language}&sheetPerUser=true`;
    const response = await apiExcel.get(url);
    const fileName = me.getFilenameFromHeader(
      response.headers,
      `${me.title}.xlsx`
    );
    fileSaver.saveAs(response.data, fileName);
  },

  openColumnSelectionDialog() {
    const dataGrid = this.$refs.dataGrid;
    this.$refs.columnSelectionDialog.show(dataGrid, this.showOnlyOneDay);
  },

  async loadUserSettings() {
    const me = this;
    const response = await this.$api.get(
      `UserSetting?settingName=${me.userSettingsKey}`
    );
    me.settings = response.data;
    if (me.settings.SettingValue) {
      me.columnSettings = JSON.parse(me.settings.SettingValue);
    }
  },

  async saveUserSettings() {
    const me = this;
    me.updateSettingsByColumns();
    const json = JSON.stringify(me.columnSettings);
    me.settings.SettingValue = json;
    if (!me.settings.SettingName) return;
    this.$api.post("UserSetting", me.settings);
  },

  // updateSettingsByColumns() {
  //     const dataGrid = this.$refs.dataGrid;
  //     if (!dataGrid) this.return;

  //     if (!this.columnSettings) {
  //         this.columnSettings = {
  //             columnInfos: []
  //         }
  //     }
  //     this.columnSettings.headerColumnCount = 0;
  //     this.columnSettings.firstField = null;
  //     const columnDefs = dataGrid.api.getColumnDefs();
  //     columnDefs.forEach(columnDef => {
  //         const column = dataGrid.api.getColumn(columnDef.colId);
  //         let columnInfo = this.columnSettings.columnInfos.find(c => c.name == columnDef.columnName);
  //         if (columnInfo == null) {
  //             columnInfo = {
  //                 name: columnDef.columnName,
  //                 width: column.actualWidth,
  //                 visible: column.visible,
  //                 field: columnDef.field,
  //                 isHeaderColumn: columnDef.isHeaderColumn
  //             };
  //             this.columnSettings.columnInfos.push(columnInfo);
  //         } else {
  //             columnInfo.width = column.actualWidth;
  //             columnInfo.visible = column.visible;
  //         }
  //         if (this.columnSettings.firstField == null && !columnDef.hide) this.columnSettings.firstField = columnDef.field;
  //         if (columnDef.isHeaderColumn && !columnDef.hide) this.columnSettings.headerColumnCount++;
  //     });
  // },

  setGridTheme(darkMode) {
    this.gridThemeName =
      darkMode == true ? "ag-theme-alpine-dark" : "ag-theme-alpine";
  },

  setGridLanguage() {
    this.updateHeaderTexts();
    this.updateColumns();
    this.updateTotals();
  },

  getButtonStyle() {
    if (this.$gus.data.darkMode) return "background:#444";
    return "background:#F5F5F5";
  },

  async sizeColumnsToFit() {
    const me = this;
    const dataGrid = this.$refs.dataGrid;
    dataGrid.api.sizeColumnsToFit();
    me.saveUserSettings();
  },

  async copyAsTemplateForNextWeek() {
    await this.$api.post(`WorkWeek/clone/${this.data.WorkWeekUID}`);
    this.$alert(this.$t("DataHasBeenCopiedAsTemplateForTheNextWeek"));
  },

  showAlert(text, delay) {
    this.alertText = text;
    setTimeout(() => (this.displayAlert = false), delay);
    this.displayAlert = true;
  },

  getReleaseTooltipKey()
  {
    const key = this.showOnlyOneDay === true ? "ReleaseDayForControl" : "ReleaseWeekForControl";
    return key;
  },
  
  getReturnTooltipKey()
  {
     const key = this.showOnlyOneDay === true ? "ReturnDayForProcessing" : "ReturnWeekForProcessing";
     return key;
  },
  
  getBookingTooltipKey()
  {
     const key = this.showOnlyOneDay === true ? "ReleaseDayForBooking" : "ReleaseWeekForBooking";
     return key;
  },  
};
