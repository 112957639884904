export default {
    updateColumnsBySettings() {
      if (!this.columnDefs) return;
      this.columnDefs.forEach((columnDef) => {
        if (this.columnSettings != null) {
          const x = this.columnSettings.columnInfos.find(
            (c) => c.name == columnDef.columnName
          );
          if (x != null) {
            columnDef.hide = x != null && x.visible != true;
            columnDef.width = x.width;
          }
        }
        const dataGrid = this.$refs.dataGrid;
        if (dataGrid) {
          dataGrid.gridOptions.suppressPropertyNamesCheck = true;
          dataGrid.api.setGridOption('columnDefs', this.columnDefs);
        }
      });
    },
  
    updateHeaders() {
      this.updateColumns();
      this.updateTotalHeaders();
      this.$refs.dataGrid.refreshCells();
    },
  
    //Updates localizations (e.g. after language changed)
    //and maybe also other columnDef changes to the columns (columnDef changes are not automatically updated)
    updateColumns() {
      const me = this;
      if (!me.columnDefs) return;
      if (me.columnSettings != null) {
        me.columnDefs.forEach((columnDef) => {
          const x = me.columnSettings.columnInfos.find(
            (c) => c.name == columnDef.columnName
          );
          if (x != null) {
            columnDef.headerName = this.$t(x.name);
          }
        });
      }
      const dataGrid = me.$refs.dataGrid;
      if (dataGrid) {
        dataGrid.gridOptions.suppressPropertyNamesCheck = true;
        dataGrid.api.setGridOption('columnDefs', this.columnDefs);
      }
    },
  };
    