export default {
  async initDataGrid() {
    const me = this;

    const dataGrid = this.$refs.dataGrid;
    if (dataGrid) {
      dataGrid.gridOptions.suppressPropertyNamesCheck = true;
      dataGrid.gridOptions.undoRedoCellEditing = true;
    }

    let columnInfoMap = {};
    this.columnSettings.columnInfos.forEach((element) => {
      columnInfoMap[element.ColumnName] = element;
    });

    me.initCustomCssClassRules();
    me.defaultColumnDefs = {
      editable: true,
      resizable: true,
    };
    me.columnDefs = me.createHeaderColumnDefs(columnInfoMap);
    me.columnDefs.push(...me.createValueColumnDefs(columnInfoMap));
    me.columnDefs.push(me.createTotalColumnDef(columnInfoMap));
  },

  initCustomCssClassRules() {
    const me = this;
    me.rowClassRules = {
      "highlight-for-deletion": "data.HasError == true",
      "highlight-row-for-change": "data.HasChanges == true",
    };
    me.valueCellClassRules = {
      normal: function(params) {
        return me.useCssStyle(params, "Normal", me.getStatusByWeekDayCustom);
      },
      finalizedDark: function(params) {
        if (me.$gus.data.darkMode === false) return false;
        return me.useCssStyle(params, "Finalized", me.getStatusByWeekDayCustom);
      },
      bookingDark: function(params) {
        if (me.$gus.data.darkMode === false) return false;
        return me.useCssStyle(params, "Booking", me.getStatusByWeekDayCustom);
      },
      bookedDark: function(params) {
        if (me.$gus.data.darkMode === false) return false;
        return me.useCssStyle(params, "Booked", me.getStatusByWeekDayCustom);
      },
      finalizedBright: function(params) {
        if (me.$gus.data.darkMode === true) return false;
        return me.useCssStyle(params, "Finalized", me.getStatusByWeekDayCustom);
      },
      bookingBright: function(params) {
        if (me.$gus.data.darkMode === true) return false;
        return me.useCssStyle(params, "Booking", me.getStatusByWeekDayCustom);
      },
      bookedBright: function(params) {
        if (me.$gus.data.darkMode === true) return false;
        return me.useCssStyle(params, "Booked", me.getStatusByWeekDayCustom);
      },
      "highlight-for-change": function(params) {
        const hasChangesFieldName = params.colDef.field + "_HasChanges";
        return params.data[hasChangesFieldName] === true;
      },
    };
  },

  getStatusByWeekDayCustom(params) {
    if (params.data.WeekState == null) return "Normal";
    return this.getStatusByWeekDay(
      params.colDef.weekdayNo,
      params.data.WeekState.DayStatus
    );
  },

  getColumnInfoValue(map, columnName, propertyName, defaultValue) {
    let info = map[columnName];
    if (info == null || info[propertyName == null]) {
      return defaultValue;
    } else {
      return map[columnName][propertyName];
    }
  },

  createValueColumnDef(weekday, weekdayNo, columnInfoMap) {
    const me = this;
    const columnName = `Column_WorkHours${weekday}`;
    return {
      field: `${weekday}Value`,
      columnName: columnName,
      headerName: me.$t(columnName),
      isValueColumn: true,
      weekdayNo: weekdayNo,
      editable: (params) => me.isDayEditable(params, weekdayNo),
      minWidth: 70,
      maxWidth: 110,
      width: me.getColumnInfoValue(columnInfoMap, columnName, "width", 80),
      hide: !me.getColumnInfoValue(columnInfoMap, columnName, "visible", true),
      resizable: true,
      cellEditor: "WorkOrExpenseEditor",
      type: "rightAligned",
      cellClassRules: me.valueCellClassRules,
      cellRenderer: "valueRenderer",
      valueFormatter: (params) => {
        return this.formatWorkOrExpenses(params);
      },
    };
  },

  createValueColumnDefs(columnInfoMap) {
    const me = this;
    const valueColumnDefs = [
      me.createValueColumnDef("Monday", 0, columnInfoMap),
      me.createValueColumnDef("Tuesday", 1, columnInfoMap),
      me.createValueColumnDef("Wednesday", 2, columnInfoMap),
      me.createValueColumnDef("Thursday", 3, columnInfoMap),
      me.createValueColumnDef("Friday", 4, columnInfoMap),
      me.createValueColumnDef("Saturday", 5, columnInfoMap),
      me.createValueColumnDef("Sunday", 6, columnInfoMap),
    ];
    return valueColumnDefs;
  },

  createHeaderColumnDefs(columnInfoMap) {
    const me = this;
    var headerColumnDefs = [];
    if (this.hasRolesCsv("Controller,Teamleader")) {
      headerColumnDefs.push({
        field: "UserUID",
        columnName: "Column_Employee",
        headerName: me.$t("Column_Employee"),
        isHeaderColumn: true,
        editable: (params) =>
          me.isHeaderColumnEditable(params) && me.isWeekEditable(params),
        resizable: true,
        hide: !me.getColumnInfoValue(
          columnInfoMap,
          "Column_Employee",
          "visible",
          true
        ),
        width: me.getColumnInfoValue(
          columnInfoMap,
          "Column_Employee",
          "width",
          160
        ),
        cellEditor: "ComboBoxEditor",
        cellEditorParams: {
          values: me.teamUsers,
          keyField: "UserUID",
          displayField: "UserName",
        },
        colSpan: this.getHeaderColSpan,
        cellClassRules: me.cellClassRules,
        valueFormatter: (params) => {
          if (params.value && me.userLookup[params.value]) {
            return me.userLookup[params.value].UserName;
          }
          return "";
        },
        cellRendererSelector: me.getDefaultRenderer,
      });
    }

    headerColumnDefs.push({
      field: "WorkTypeUIDAndNameAndKey",
      columnName: "Column_WorkTypeID",
      isHeaderColumn: true,
      colSpan: this.getHeaderColSpan,
      headerName: me.$t("Column_WorkTypeID"),
      editable: (params) =>
        me.isHeaderColumnEditable(params) && me.isWeekEditable(params),
      resizable: true,
      hide: !me.getColumnInfoValue(
        columnInfoMap,
        "Column_WorkTypeID",
        "visible",
        true
      ),
      width: me.getColumnInfoValue(
        columnInfoMap,
        "Column_WorkTypeID",
        "width",
        160
      ),
      cellEditor: "LookupEditor",
      cellEditorParams: {
        caption: me.$t("SelectWorkType"),
        apiUrl: "WorkTypes",
        columnDefs: [
          {
            field: "WorkTypeID",
            headerName: me.$t("WorktypeId"),
            editable: false,
            resizable: true,
            width: 100,
          },
          {
            field: "WorkType",
            headerName: me.$t("Worktype"),
            editable: false,
            resizable: true,
            width: 180,
          },
          {
            field: "WorkCategory",
            headerName: me.$t("Worktype_Category"),
            editable: false,
            resizable: true,
            width: 180,
          },
        ],
        keyField: "WorkTypeUID",
        displayField: "WorkTypeAndId",
        searchExactField: "WorkTypeID",
        displayFormatDelegate: (data) => {
          return `${data["WorkTypeID"]} - ${data["WorkType"]}`;
        },
        writeBackDelegate: (value, selectedItem) => {
          value.WorkTypeID = selectedItem["WorkTypeID"];
          value.WorkType = selectedItem["WorkType"];
        },
        preFilterDelegate: (data) => {
          if (me.workTypeUIDs.includes(data.WorkTypeUID) == false) return false;
          if (data.WorkCategoryID == 3) return false;
          return true;
        },
      },
      cellClassRules: me.cellClassRules,
      valueFormatter: (params) => {
        if (params.node.rowPinned) return params.value; //Possible total caption
        if (params.value) {
          const obj = JSON.parse(params.value);
          return obj.WorkTypeAndId;
        }
        return "";
      },
      cellRendererSelector: me.getDefaultRenderer,
    });

    headerColumnDefs.push({
      field: "CostCenterJson",
      columnName: "Column_CostCenter",
      isHeaderColumn: true,
      colSpan: this.getHeaderColSpan,
      headerName: me.$t("Column_CostCenter"),
      editable: (params) =>
        me.isHeaderColumnEditable(params) && me.isWeekEditable(params),
      cellClassRules: me.cellClassRules,
      hide: !me.getColumnInfoValue(
        columnInfoMap,
        "Column_CostCenter",
        "visible",
        false
      ),
      width: me.getColumnInfoValue(
        columnInfoMap,
        "Column_CostCenter",
        "width",
        130
      ),
      cellEditor: "LookupEditor",
      cellEditorParams: {
        caption: me.$t("SelectCostCenter"),
        apiUrl: "CostCenters",
        columnDefs: [
          {
            field: "CostCenterID",
            headerName: me.$t("CostCenterID"),
            editable: false,
            resizable: true,
            width: 150,
          },
          {
            field: "CostCenterDescription",
            headerName: me.$t("CostCenterDescription"),
            editable: false,
            resizable: true,
            width: 300,
          },
        ],
        keyField: "CostCenterID",
        displayField: "CostCenterDisplayField",
        searchExactField: "CostCenterID",
        displayFormatDelegate: (data) => {
          return `${data["CostCenterID"]} - ${data["CostCenterDescription"]}`;
        },
        writeBackDelegate: (value, selectedItem) => {
          value.CostCenterID = selectedItem["CostCenterID"];
          value.CostCenterDescription = selectedItem["CostCenterDescription"];
        },
      },
      resizable: true,
      valueFormatter: (params) => {
        if (params.node.rowPinned) return params.value; //Possible total caption
        if (params.value) {
          const obj = JSON.parse(params.value);
          return obj.CostCenterDisplayField;
        }
        return "";
      },
      cellRendererSelector: me.getDefaultRenderer,
    });

    headerColumnDefs.push(
      {
        field: "WorkCategory",
        columnName: "Column_WorkCategory",
        headerName: me.$t("Column_WorkCategory"),
        isHeaderColumn: true,
        colSpan: this.getHeaderColSpan,
        editable: false,
        cellClassRules: me.cellClassRules,
        width: me.getColumnInfoValue(
          columnInfoMap,
          "Column_WorkCategory",
          "width",
          130
        ),
        hide: !me.getColumnInfoValue(
          columnInfoMap,
          "Column_WorkCategory",
          "visible",
          false
        ),
        resizable: true,
        cellRendererSelector: me.getDefaultRenderer,
      },
      {
        field: "BookingText",
        columnName: "Column_BookingText",
        headerName: me.$t("Column_BookingText"),
        isHeaderColumn: true,
        colSpan: this.getHeaderColSpan,
        editable: (params) =>
          me.isHeaderColumnEditable(params) && me.isWeekEditable(params),
        cellClassRules: me.cellClassRules,
        minWidth: 180,
        maxWidth: 500,
        width: me.getColumnInfoValue(
          columnInfoMap,
          "Column_BookingText",
          "width",
          240
        ),
        hide: !me.getColumnInfoValue(
          columnInfoMap,
          "Column_BookingText",
          "visible",
          true
        ),
        resizable: true,
        cellRenderer: "bookingTextRenderer",
        cellEditor: "MultilineEditor",
        cellEditorParams: {
          caption: me.$t("Column_BookingText"),
        },
        cellRendererSelector: me.getDefaultRenderer,
      }
    );
    return headerColumnDefs;
  },

  createTotalColumnDef(columnInfoMap) {
    const me = this;
    const totalValue = {
      field: "TotalsValue",
      columnName: "Column_WorkHoursTotal",
      headerName: me.$t("Column_WorkHoursTotal"),
      editable: false,
      isTotalColumn: true,
      width: me.getColumnInfoValue(
        columnInfoMap,
        "Column_WorkHoursTotal",
        "width",
        120
      ),
      hide: !me.getColumnInfoValue(
        columnInfoMap,
        "Column_WorkHoursTotal",
        "visible",
        true
      ),
      resizable: true,
      type: "rightAligned",
      cellClassRules: me.cellClassRules,
      valueFormatter: (params) => {
        return this.formatWorkOrExpenses(params);
      },
      cellRenderer: "formattedBoldRenderer",
    };
    return totalValue;
  },
};
