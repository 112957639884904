export default {
    show(dataGrid, hideValueColumns) {
        const me = this;
        me.columnDefs = dataGrid.api.getColumnDefs().slice();
        me.data.columnInfos = [];
        me.data.headerColumnCount = 0;
        me.data.firstField = null;
        me.columnDefs.forEach(columnDef => {

            //continue if columns should not be displayed at all
            //if (hideValueColumns === true && columnDef.isValueColumn === true) return; // note: this "return" is like continue in C#

            if (me.data.firstField == null && !columnDef.hide) me.data.firstField = columnDef.field;
            if (columnDef.isHeaderColumn) me.data.headerColumnCount++;
            const columnInfo = {
                name: columnDef.columnName,
                visible: !columnDef.hide,
                width: columnDef.width,
                field: columnDef.field,
                isHeaderColumn: columnDef.isHeaderColumn,
                hideFromSelection: hideValueColumns === true && columnDef.isValueColumn === true
            };
            me.data.columnInfos.push(columnInfo);
        });
        me.originalData = JSON.parse(JSON.stringify(this.data));
        this.dialog = true;
    },

    updateMetaInfo() {
        const me = this;
        me.data.firstField = null;
        me.data.headerColumnCount = 0;
        me.data.columnInfos.forEach(columnInfo => {
            if (me.data.firstField == null && columnInfo.visible == true) me.data.firstField = columnInfo.field;
            if (columnInfo.isHeaderColumn == true && columnInfo.visible == true) me.data.headerColumnCount++;
        });
    },

    onChange() {
        this.updateMetaInfo();
        this.$emit('columnSelectionChanged', this.data);
    },
    onOK() {
        this.updateMetaInfo();
        this.$emit('columnSelectionOK', this.data);
        this.dialog = false;
    },
    onCancel() {
        this.$emit('columnSelectionChanged', this.originalData);
        this.dialog = false;
    }
}