import api from '@/api.js';
export default {

    async loadData() {
        const me = this;
        me.loadingTemplate = this.$t('ProcessingData');
        me.$refs.dataGrid.api.showLoadingOverlay();

        me.updatePeriodText();
        const res = await api.get(`workoverview/month?dayInMonth=${me.currentDate.format('YYYY-MM-DD')}`);

        me.$refs.dataGrid.api.hideOverlay();
        me.data = res.data;

    },

    updatePeriodText() {
        const me = this;
        const locale = this.$i18n.locale;
        me.periodText = `${me.currentDate.locale(locale).format('MMMM')} ${me.currentDate.format("YYYY")}`;
    }

}