import moment from "moment";
export default {
  updateColumnsBySettings() {
    if (!this.columnDefs) return;
    this.columnDefs.forEach((columnDef) => {
      const x = this.columnSettings.columnInfos.find(
        (c) => c.name == columnDef.columnName
      );
      if (x != null) {
        columnDef.hide = x != null && x.visible != true;
        columnDef.width = x.width; // * 1.5; //TODO: Temporary change -> Factor 1,5 needs to applied to the stored value only once on initial load
      }
    });
    const dataGrid = this.$refs.dataGrid;
    if (dataGrid) {
      dataGrid.gridOptions.suppressPropertyNamesCheck = true;
      dataGrid.api.setGridOption('columnDefs', this.columnDefs);
    }
  },

  updateHeaders() {
    this.updateColumns();
    this.updateTotalHeaders();
  },

  updateColumns() {
    const me = this;
    if (!me.columnDefs) return;

    const startDate = me.$dates.mondayDate;
    me.columnDefs.forEach((columnDef) => {
      const x = me.columnSettings.columnInfos.find(
        (c) => c.name == columnDef.columnName
      );
      if (x != null) {
        if (columnDef.isValueColumn)
        {
           let columnDate = moment(startDate);
           columnDate.add(columnDef.weekdayNo, 'days');
           columnDef.headerName = this.$t(x.name) + ' ' + columnDate.format('DD');
        }
        else
        {
          columnDef.headerName = this.$t(x.name);
        }
        
        if (
          me.showOnlyOneDay === true &&
          (columnDef.isValueColumn === true || columnDef.isTotalColumn === true)
        ) {
          const weekdayName = moment(me.$dates.currentDate)
            .locale("en")
            .format("dddd");
          columnDef.hide = columnDef.columnName.indexOf(weekdayName) < 0;
        }
      }
    });
    const dataGrid = me.$refs.dataGrid;
    if (dataGrid) {
      dataGrid.gridOptions.suppressPropertyNamesCheck = true;
      dataGrid.api.setGridOption('columnDefs', this.columnDefs);
    }
  },

  updateHeaderTexts() {
    const me = this;
    const locale = this.$i18n.locale;
    if (me.showOnlyOneDay === true) {
      me.periodText = me.$dates.currentDate
        .locale(locale)
        .format("dd DD. MMM YYYY");
    } else {
      const weekStartDateFormatted = me.$dates.mondayDate
        .locale(locale)
        .format("dd DD. MMM YYYY");
      const weekEndDateFormatted = me.$dates.mondayDate
        .clone()
        .add(6, "days")
        .locale(locale)
        .format("dd DD. MMM YYYY");
      me.periodText = `${weekStartDateFormatted} .. ${weekEndDateFormatted}`;
    }
    me.statusText = me.$t(me.statusTextCode);
  },

  updateTotalHeaders() {
    const dataGrid = this.$refs.dataGrid;
    if (!dataGrid) return;
    const columnDefs = dataGrid.api.getColumnDefs();
    columnDefs.forEach((columnDef) => {
      if (columnDef.isHeaderColumn == true && !columnDef.hide) {
        this.totalsRowData[0][columnDef.field] = "";
        this.totalsRowData[1][columnDef.field] = "";
      }
    });
    this.totalsRowData[0][
      this.columnSettings.firstField
    ] = this.getTotalWorkCaption();
    this.totalsRowData[1][
      this.columnSettings.firstField
    ] = this.getTotalExpensesCaption();
    dataGrid.api.setGridOption('pinnedBottomRowData', this.totalsRowData);
  },

  updateTotalsInRow(row) {
    const me = this;
    row.TotalsValue =
      me.toDecimal(row.MondayValue) +
      me.toDecimal(row.TuesdayValue) +
      me.toDecimal(row.WednesdayValue) +
      me.toDecimal(row.ThursdayValue) +
      me.toDecimal(row.FridayValue) +
      me.toDecimal(row.SaturdayValue) +
      me.toDecimal(row.SundayValue);
  },

  updateTotals(changeEvent) {
    const me = this;
    const dataGrid = this.$refs.dataGrid;
    if (!dataGrid) return;

    let totalWorkRow = this.totalsRowData[0];
    let totalExpensesRow = this.totalsRowData[1];
    me.initRowValuesToZero(totalWorkRow);
    me.initRowValuesToZero(totalExpensesRow);
    dataGrid.api.setGridOption('pinnedBottomRowData', this.totalsRowData);
    if (
      !this.data ||
      !this.data.Rows ||
      !this.totalsRowData ||
      this.totalsRowData.length < 2
    )
      return;

    if (changeEvent != null) {
      let rowData = changeEvent.data;
      me.updateTotalsInRow(rowData);
      changeEvent.api.refreshCells({
        force: true,
        rowNodes: [changeEvent.node], // pass rowNode that was edited
      });
    } else {
      me.data.Rows.forEach((row) => {
        me.updateTotalsInRow(row);
      });
      dataGrid.api.refreshCells();
    }
    me.data.Rows.forEach((row) => {
      if (row.WorkCategoryID != 4) {
        me.addRowValuesToTotalRow(row, totalWorkRow, 1, row.WorkCategoryID);
      } else {
        const costFactor = me.getCostfactor(row.WorkTypeUID);
        me.addRowValuesToTotalRow(
          row,
          totalExpensesRow,
          costFactor,
          row.WorkCategoryID
        );
      }
    });
    dataGrid.api.setGridOption('pinnedBottomRowData', this.totalsRowData);
  },

  initRowValuesToZero(row) {
    row.MondayValue = 0;
    row.TuesdayValue = 0;
    row.WednesdayValue = 0;
    row.ThursdayValue = 0;
    row.FridayValue = 0;
    row.SaturdayValue = 0;
    row.SundayValue = 0;
    row.TotalsValue = 0;
  },
};
