<template>
  <v-container fluid>
    <v-row>
      <table class="px-4 mt-0 pt-0" style="min-height: 50px">
        <tr>
          <td>
            <span class="pr-4">{{ $t("TimeRecordingCorrection") }}</span>
          </td>
          <td>
            <span class="mx-4" style="font-size: 13px">{{ periodText }}</span>
          </td>
          <td v-if="hasRoles(['Teamleader', 'Controller'])">
            <v-select
              ref="selectUser"
              style="max-width: 140px; font-size: 12px"
              class="pa-0 mt-4 ml-6 mr-0"
              dense
              item-text="UserName"
              item-value="UserUID"
              :items="users"
              @change="onSelectedUserChanged($event)"
              v-model="selectedUserUID"
            ></v-select>
          </td>
          <td width="300">
            <v-checkbox
              class="checkbox pa-0 mt-4 ml-6 mr-0"
              dense
              @change="refresh"
              v-model="showDeleted"
              :label="$t('ShowDeleted')"
            >
            </v-checkbox>
          </td>
        </tr>
      </table>
    </v-row>
    <p :style="getStyle">
      <ag-grid-vue
        style="width: 100%; height: 100%"
        :class="gridThemeName"
        ref="dataGrid"
        rowSelection="single"
        :overlayNoRowsTemplate="noRowsTemplate"
        :overlayLoadingTemplate="loadingTemplate"
        :columnDefs="columnDefs"
        :rowData="items"
        @rowClicked="onRowClicked($event)"
      ></ag-grid-vue>
    </p>
    <v-dialog
      overlay-color="blue darken-3"
      v-model="dialog"
      scrollable
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span>{{ title }}</span>
          <v-spacer></v-spacer>
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon @click="dialog = false" v-bind="attrs" v-on="on">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
          </v-menu>
        </v-card-title>
        <v-card-text style="height: 335px" class="py-0">
          <v-container fluid>
            <v-form ref="form" v-model="valid">
              <v-row>
                <v-col>
                  <v-card elevation="0">
                    <table>
                      <tr>
                        <td>
                          <v-text-field
                            readonly
                            @focus="$event.target.select()"
                            :label="$t('Date')"
                            v-model="record.DateFormatted"
                            style="max-width: 130px"
                            append-icon="mdi-calendar"
                            @click:append="showDatePicker"
                          >
                          </v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            readonly
                            ref="txtTime"
                            :label="$t('Time')"
                            v-model="record.Time"
                            style="max-width: 130px; margin-left: 10px"
                            append-icon="mdi-clock"
                            @click:append="showTimePicker"
                          >
                          </v-text-field>
                        </td>
                      </tr>
                    </table>
                    <v-radio-group v-model="record.TrackType">
                      <v-radio :label="$t('Coming')" :value="0"></v-radio>
                      <v-radio :label="$t('Leaving')" :value="1"></v-radio>
                      <v-text-field
                        ref="txtComment"
                        class="mt-4"
                        :label="$t('Comment')"
                        v-model="record.Remark"
                        style="max-width: 400px"
                      ></v-text-field>
                      <v-checkbox
                        ref="chkIsDeleted"
                        class="ma-0"
                        :label="$t('IsDeleted')"
                        v-model="record.IsDeleted"
                        style="max-width: 400px"
                      ></v-checkbox>
                    </v-radio-group>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="standard" @click="onCancel()">
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn color="primary" @click="onOK()" width="100">
            {{ $t("OK") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      ref="datePickerDialog"
      overlay-color="blue darken-3"
      v-model="showDatePickerDialog"
      scrollable
      max-width="400px"
    >
      <v-card>
        <v-card-title>
          <span>{{ $t("SelectDate") }}</span>
          <v-spacer></v-spacer>
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                @click="showDatePickerDialog = false"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
          </v-menu>
        </v-card-title>
        <v-card-text style="height: 450px">
          <v-container fluid>
            <v-date-picker v-model="datePickerDate"> </v-date-picker>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="standard" @click="onDatePickerCancel()">
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn color="primary" @click="onDatePickerOK()" width="100">
            {{ $t("OK") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      ref="timePickerDialog"
      overlay-color="blue darken-3"
      v-model="showTimePickerDialog"
      scrollable
      max-width="380px"
    >
      <v-card>
        <v-card-title>
          <span>{{ $t("SelectTime") }}</span>
          <v-spacer></v-spacer>
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon @click="dialog = false" v-bind="attrs" v-on="on">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
          </v-menu>
        </v-card-title>
        <v-card-text style="height: 450px">
          <v-container fluid>
            <v-time-picker
              v-model="timePickerTime"
              full-width
              :label="$t('Time')"
              format="24hr"
            >
            </v-time-picker>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="standard" @click="onTimePickerCancel()">
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn color="primary" @click="onTimePickerOK()" width="100">
            {{ $t("OK") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <day-or-week-selection-dialog
      ref="dayOrWeekSelectionDialog"
      @onOK="onWeekdaySelectionChanged($event)"
    ></day-or-week-selection-dialog>
  </v-container>
</template>
<style lang="css">
@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-alpine.css";
.highlight-for-deletion {
  background-color: #ff6f00 !important;
}
.checkbox .v-label {
  font-size: 12px;
}
</style>
<script>
import { AgGridVue } from "ag-grid-vue";
import ReadOnlyRenderer from "@/renderers/readOnlyRenderer.js";
import DateTimeRenderer from "@/renderers/dateTimeRenderer.js";
import moment from "moment";
import utils from "@/common/utils.js";
import api from "@/api.js";
import DayOrWeekSelectionDialog from "@/components/dialogs/DayOrWeekSelectionDialog/_Index.vue";
import { showSnackbar } from "@/common/globalActions";

export default {
  name: "time-recording-correction-list",

  props: {
    currentDate: Object, //Date but wrapped as as object of the moment lib
  },

  data: () => ({
    showTimePickerDialog: false,
    showDatePickerDialog: false,
    gridThemeName: "ag-theme-alpine-dark",
    items: [],
    columnDefs: [],
    periodText: "",
    noRowsTemplate: null,
    loadingTemplate: null,
    mode: 1, //record worktime
    users: [],
    selectedUserUID: null,
    dialog: false,
    valid: true,
    record: {
      time: "",
    },
    datePickerDate: null,
    timePickerTime: null,
    title: "",
    isDateTimeEditable: false,
    showDeleted: false,
  }),

  async created() {},

  beforeMount() {
    this.initialize();
  },

  mounted() {},

  methods: {
    ...utils,

    showDatePicker() {
      if (this.isDateTimeEditable == false) {
        showSnackbar(this.$t("DateAndTimeOnlyEditableForNewEntries"), "warning");
        return;
      }
      this.datePickerDate = this.record.Date;
      this.showDatePickerDialog = true;
    },

    onDatePickerOK() {
      this.record.Date = this.datePickerDate;
      const timeString = this.record.Date + " " + this.record.Time;
      this.record.TrackedTime = moment(timeString).toDate(); //TODO time / utc
      this.setHelperDateProps(this.record);
      this.showDatePickerDialog = false;
    },

    onDatePickerCancel() {
      this.showDatePickerDialog = false;
    },

    showTimePicker() {
      if (this.isDateTimeEditable == false) {
        showSnackbar(this.$t("DateAndTimeOnlyEditableForNewEntries"), "warning");
        return;
      }
      this.timePickerTime = this.record.Time;
      this.showTimePickerDialog = true;
    },

    onTimePickerOK() {
      this.record.Time = this.timePickerTime;
      const timeString = this.record.Date + " " + this.record.Time;
      this.record.TrackedTime = moment(timeString).toDate(); //TODO time / utc
      this.setHelperDateProps(this.record);
      this.showTimePickerDialog = false;
    },

    onTimePickerCancel() {
      this.showTimePickerDialog = false;
    },

    async initialize() {
      this.noRowsTemplate = this.$t("NoRowsFound");
      this.loadingTemplate = this.$t("ProcessingData");
      this.fillUsers();
      this.selectedUserUID = this.$user.UserUID;
      this.setGridTheme(this.$gus.data.darkMode);
      this.columnDefs = this.createColumnDefs();
    },

    onSelectedUserChanged() {
      this.refresh();
    },

    async refresh() {
      const me = this;
      const locale = this.$gus.data.languageCode;
      const weekStartDateFormatted = me.currentDate
        .locale(locale)
        .format("dd DD. MMM YYYY");
      const weekEndDateFormatted = me.currentDate
        .clone()
        .add(6, "days")
        .locale(locale)
        .format("dd DD. MMM YYYY");
      me.periodText = `${weekStartDateFormatted} .. ${weekEndDateFormatted}`;

      const url = `TimeCard/week?mondayDate=${me.currentDate.format(
        "YYYY-MM-DD"
      )}&userUID=${this.selectedUserUID}&onlyActive=false`;
      const res = await api.get(url);
      if (res.data) {
        res.data.forEach(
          (e) =>
            (e.TrackTypeText = me.$t(e.TrackType == 0 ? "Coming" : "Leaving"))
        );
      }
      this.items =
        this.showDeleted === true
          ? res.data
          : res.data.filter((x) => x.IsDeleted == 0);
    },

    onRowClicked(e) {
      this.record = utils.deepClone(e.data);
      this.setHelperDateProps(this.record);
      this.title = this.$t("EditTimeRecording");
      this.isDateTimeEditable = false;
      this.dialog = true;
    },

    setHelperDateProps(record) {
      record.Date = moment(record.TrackedTime).local().format("YYYY-MM-DD");
      record.Time = moment(record.TrackedTime).local().format("HH:mm");
      record.DateFormatted = moment(record.TrackedTime)
        .local()
        .format("DD.MM.YYYY");
      record.TrackedTimeText = moment(record.TrackedTime).format(
        "YYYY-MM-DD H:m:s"
      );
    },

    async insertNewRow() {
      const me = this;
      const res = await api.get(`device?userUID=${me.selectedUserUID}`);
      const device = res.data.find((x) => x.WebDefault == true);
      if (!device) return;
      let tzOffsetHours = Math.floor(moment().utcOffset() / 60);
      me.record = {
        TrackedTime: new Date(),
        TZOffset: tzOffsetHours,
        TrackType: 1,
        Remark: "",
        IsDeleted: false,
        UserUID: me.selectedUserUID,
        DeviceID: device.DeviceID,
      };
      this.setHelperDateProps(this.record);
      this.title = this.$t("AddTimeRecording");
      this.isDateTimeEditable = true;
      this.dialog = true;
    },

    async onOK() {
      this.dialog = false;
      this.record.UserUID = this.selectedUserUID;
      await api.post("timecard", this.record);
      this.refresh();
    },

    onCancel() {
      this.dialog = false;
    },

    setGridTheme(darkMode) {
      this.gridThemeName =
        darkMode == true ? "ag-theme-alpine-dark" : "ag-theme-alpine";
    },

    createColumnDefs() {
      const me = this;
      let columnDefs = [];

      let fromColumnDef = this.createColumnDef(
        "TrackedTime",
        "Time",
        me.$t("Time"),
        200
      );
      fromColumnDef.cellRenderer = "dateTimeRenderer";
      columnDefs.push(fromColumnDef);

      columnDefs.push(
        me.createColumnDef("Description", "Device", me.$t("Device"), 200)
      );
      columnDefs.push(
        me.createColumnDef(
          "TrackTypeText",
          "TrackType",
          me.$t("TrackType"),
          150
        )
      );
      columnDefs.push(
        me.createColumnDef("Remark", "Remarks", me.$t("Remarks"), 200)
      );
      let isDeletedDef = me.createColumnDef(
        "IsDeleted",
        "IsDeleted",
        me.$t("IsDeleted"),
        100
      );
      isDeletedDef.cellRenderer = (params) => {
        return `<input type='checkbox' onclick="return false;" ${
          params.value ? "checked" : ""
        } />`;
      };
      columnDefs.push(isDeletedDef);
      columnDefs.push(
        me.createColumnDef("RemarkAuthor", "Author", me.$t("Author"), 100)
      );

      return columnDefs;
    },

    createColumnDef(field, columnName, headerName, width) {
      const columnDef = {
        field: field,
        columnName: columnName,
        headerName: headerName,
        editable: false,
        width: width,
        resizable: true,
      };
      return columnDef;
    },

    setGridLanguage() {
      this.updateColumns();
    },

    updateColumns() {
      const me = this;
      if (!me.columnDefs) return;
      me.columnDefs.forEach((columnDef) => {
        columnDef.headerName = this.$t(columnDef.columnName);
      });
      const dataGrid = me.$refs.dataGrid;
      if (dataGrid) {
        dataGrid.gridOptions.suppressPropertyNamesCheck = true;
        dataGrid.api.setGridOption('columnDefs', this.columnDefs);
      }
    },

    async fillUsers() {
      const me = this;
      if (utils.hasUserRole(me.$user, "Controller")) {
        me.users = this.$allUsers;
      } else if (utils.hasUserRole(me.$user, "Teamleader")) {
        me.users = this.$teamUsers;
      }
      me.users.push({ UserUID: me.$user.UserUID, UserName: me.$user.UserName });
    },

    navigateDateBackward() {
      this.currentDate.subtract(1, this.showOnlyOneDay === true ? "d" : "w");
      this.refresh();
    },

    navigateDateForward() {
      this.currentDate.add(1, this.showOnlyOneDay === true ? "d" : "w");
      this.refresh();
    },

    navigateToDate() {
      this.$refs.dayOrWeekSelectionDialog.show(
        moment(this.currentDate).format("YYYY-MM-DD"),
        this.showOnlyOneDay
      );
    },

    onWeekdaySelectionChanged(dateValue) {
      this.currentDate = dateValue;
      this.refresh();
    },
  },

  computed: {
    getStyle() {
      //magic number 200 is the height of the toolbar + footer
      //TODO: Calculate size dynamically instead of using a fixed magic number
      let subtractHeight = this.displayAlert ? 265 : 195;
      return (
        "width:100%;margin-top:10px;height:" +
        (parseInt(this.$vssHeight) - subtractHeight) +
        "px"
      );
    },
  },
  components: {
    AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    readOnlyRenderer: ReadOnlyRenderer,
    // eslint-disable-next-line vue/no-unused-components
    dateTimeRenderer: DateTimeRenderer,
    // eslint-disable-next-line vue/no-unused-components
    DayOrWeekSelectionDialog,
  },
};
</script>