import api from "@/api.js";
export default {
  onMounted() {
    const me = this;
    setTimeout(() => {
      if (me.$refs.textField) me.$refs.textField.focus();
    }, 50);
  },

  onCellFocused(e) {
    const nodes = e.api.getRenderedNodes();
    if (nodes) nodes[e.rowIndex].setSelected(true);
  },

  onCellDoubleClicked(e) {
    this.setSelected(e.data);
    this.dialog = false;
  },

  onCellKeyDown(e) {
    if (e.event.key == "Enter") {
      this.setSelected(e.data);
    }
  },
  onKeyDown(e) {
    const me = this;

    if (e.key == "F2") {
      me.openSearchDialog();
    } else if (e.key == "Tab" || e.key == "Enter") {
      e.cancelBubble = true;
      me.searchInField(me.searchText);
      return;
    }
  },

  async searchInField(searchText) {
    const me = this;
    await me.loadData(searchText);
    if (me.items.length == 1) {
      this.setSelected(me.items[0]);
    } else {
      me.dialogSearchText = me.searchText;
      me.dialog = true;
    }
  },

  async loadData(searchText) {
    const me = this;
    const res = await api.get(me.apiUrl); //WorkTypes
    let data = res.data;
    if (searchText && searchText.trim().length > 0) {
      data = data.filter((x) => me.searchInAllColumns(x, searchText));
    }
    me.items = data;
  },
  searchInAllColumns(x, searchText) {
    let result = false;
    this.columnDefs.forEach((columnDef) => {
      let val = x[columnDef.field].toString();
      const r = val.toLowerCase().includes(searchText.toLowerCase());
      result |= r;
      if (r == true) return true;
    });
    return result;
  },

  onGridReady() {},

  onRowDataChanged() {},

  async openSearchDialog() {
    const me = this;
    this.loadData(this.dialogSearchText);
    me.dialog = true;
  },

  onSearchDialogKeyDown() {
    this.loadData(this.dialogSearchText);
  },

  rowClicked(item) {
    this.toggleSelection(item);
  },
  toggleSelection(item) {
    if (this.selectedDialogItem == item) {
      this.selectedDialogItem = null;
    } else {
      this.selectedDialogItem = item;
    }
  },

  onKeyPress() {},

  onOK() {
    var focusedCell = this.$refs.dataGrid.api.getFocusedCell();
    var row = this.$refs.dataGrid.api.getDisplayedRowAtIndex(focusedCell.rowIndex);
    if (row == null) {
      this.isValid = false;
      return;
    }
    this.setSelected(row.data);
    this.dialog = false;
  },

  onClear() {
    this.dialogSearchText = "";
    this.loadData(this.dialogSearchText);
  },

  onTextfieldClear() {
    this.setSelected(null);
    this.dialog = false;
  },

  setSelected(item) {
    this.selectedItem = item;
    this.selectedDialogItem = item;
    this.$emit("changed", this.selectedItem);
  },

  onCancel() {
    this.dialog = false;
  },
};
