import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueScreenSize from "vue-screen-size";
import VuetifyConfirm from "vuetify-confirm";
import i18n from "./i18n";
import "./styles.css";

// import Vue from 'vue'   // in Vue 2
//import store from './store';

Vue.config.productionTip = false;

Vue.use(VueScreenSize);
Vue.use(VuetifyConfirm, {
  vuetify,
  icon: "mdi-comment-question-outline",
});

new Vue({
  router,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

Vue.prototype.$versionNo = "2.76";
